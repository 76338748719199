import React from "react";
// Customizable Area Start
import { Box, ButtonBase, CircularProgress, Drawer, InputAdornment, TextField, Typography, styled,} from "@material-ui/core";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

interface Deal {
  id: number;
  lastName: string;
}

// Customizable Area End

import HamburgerMenuFullController, {
  Props,
} from "./HamburgerMenuFullController";
import { arrowShowMore, arrowTop, car, hamburger_icon_selected, home, logo, people, searchIcon } from "./assets";

export default class HamburgerMenuFull extends HamburgerMenuFullController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  dealDataFull: Deal[] = [
    { id: 1, 
      lastName: "Johnson" 
    },
    { id: 2, 
      lastName: "Smith" 
    },
    { id: 3, 
      lastName: "Brown" 
    },
    { id: 4, 
      lastName: "Williams" 
    },
  ];

  allDealsContent =()=>{
    const filteredFullDeals = this.dealDataFull.filter(dealFull=> 
      dealFull.lastName.toLowerCase().includes(this.state.searchTerm.toLowerCase()) 
      ||
      dealFull.id.toString().includes(this.state.searchTerm)
    );
    return (
      <Box style={{ width: '100%' }}>
        <HoverButton style={{ width: '100%' }} onClick={() => this.activeTab(1)} data-test-id="AllDealsButtonTestId">
          <Box style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '260px',
            height: '45px',
            justifyContent: 'space-between',
            transition: 'all 0.1s ease',
          }}>
            <Box style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '45px',
              justifyContent: 'start',
              transition: 'all 0.1s ease',
              overflow: 'hidden',
              width: '228px',
            }}>
              <img src={car} style={{
                width: '24px',
                height: '24px',
                marginLeft: '10px',
                flexShrink: 0,
              }} />
              <Typography style={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#013D4F',
                marginLeft: '10px',
                whiteSpace: 'nowrap',
              }}>All Deals</Typography>
            </Box>
            <Box style={{
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
              transition: 'all 0.1s ease',
            }} 
            > 
              <img src={arrowTop} 
              style={{
                height: "24px",
                width: "24px",
                transform: this.state.isExpanded ?'rotate(0deg)':'rotate(180deg)',
                transition: 'transform 0.5s ease-in-out'
              }
            } 
            onClick={(e) => {
              e.stopPropagation();
              this.toggleExpand()
            }}
            data-test-id="accordion"
            />
            </Box>
          </Box>
        </HoverButton>
        <Box style={{ maxHeight: this.state.isExpanded?"199px":"0px", transition: 'max-height 0.5s ease-in-out', overflow: "hidden", justifyContent:'center', display:'flex', flexDirection:'column', width:'100%', marginLeft:'10px' }} className="collapsibleContent">
          <Box style={{height:'186px', width:'166px'}}>
              <Box style={{width:'166px', height:'32px'}}>
                <TextField 
                placeholder="Search in deals"
                onChange={this.handleSearchChange}
                data-test-id = "searchInDealstestId"
                InputProps={{
              startAdornment: (
                <>
                  <InputAdornment 
                  position="start" 
                  style={{cursor:"pointer"}}
                  >
                    <img id="searchIcon" src={searchIcon} />
                  </InputAdornment>
                </>
              ),
            }} 
            />
              </Box>
            <Box style={{marginTop:'10px'} }>
            {(this.state.searchTerm===''? this.dealDataFull : filteredFullDeals).map((dealFull)=>(
            <Box key={dealFull.id}>
            <Typography variant="body1" style={{ fontSize:'14px',fontWeight:500, color:'#989A98'}}>
              {`${dealFull.lastName}`}
            </Typography>
            </Box>
        ))}
        <Box style={{ marginTop:'6px', display:'flex', width:'132px', height:'25px',justifyContent:'space-between'}}>
            <Typography style={{color:'#C5CBC9' ,fontWeight:400, fontSize:'16px'}}>
              show more
            </Typography>
            <img src={arrowShowMore} 
            style={{
                color:'#C5CBC9',
                width: "24px",
                transform: this.state.showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.5s ease-in-out',
                height: "24px",
                marginRight:'16px'
              }} />
        </Box>
        </Box>
        </Box>
        </Box>
      </Box>
    )
}

  mainSideBarContent=()=>{
    return (
      <Box style={{display:'flex', width:'100%', justifyContent:'center'}}>
              <Box style={{
                marginTop: '40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '260px' ,
                height: '205px',
                transition: 'all 0.1s ease',
                maxHeight: '460px',
              }}>
                <HoverButton style={{ width: '100%', marginBottom:'35px' }} onClick={() => this.activeTab(0)} data-test-id="homeButtonTestId">
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '260px',
                    height: '45px',
                    justifyContent: 'start',
                    transition: 'all 0.1s ease',
                  }}>
                    <img src={home} style={{
                      width: '24px',
                      height: '24px',
                      paddingLeft: '10px',
                      transition: 'padding-left 0.1s ease',
                    }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#013D4F',
                      marginLeft: '10px',
                      transition: 'margin-left 0.1s ease',
                    }}>Home</Typography>
                  </Box>
                </HoverButton>
                {this.allDealsContent()}
                <HoverButton style={{ width: '100%', marginTop:'35px' }} onClick={() => this.activeTab(2)} data-test-id="MyTeamsButtonTestId">
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '260px',
                    height: '45px',
                    justifyContent: 'start',
                    transition: 'all 0.1s ease',
                  }}>
                    <img src={people} style={{
                      width: '24px',
                      height: '24px',
                      paddingLeft: '10px',
                      transition: 'padding-left 0.1s ease',
                    }} />
                    <Typography style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#013D4F',
                      marginLeft: '10px',
                      transition: 'margin-left 0.1s ease',
                    }}>My Teams</Typography>
                  </Box>
                </HoverButton>
              </Box>
            </Box>
    )
  }

  renderHamburgerMenu = () => {
    return (
      <Box>
        {this.renderOverlay()}
        <Drawer
          anchor={"left"}
          open={true}
          style={{ zIndex: 1200 }}
          onClose={this.isOpen}
          variant="persistent"
          PaperProps={{
            style: {
              width: this.state.isOpen ? '330px' : '0px',
              transition: 'width 0.1s',
              overflowX: 'hidden',
              borderRight: '1px solid #F0FAFA',
            },
          }}
        >
          <Box style={{
            width: `${'330px'}`,
            height: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "flex-start",
            alignItems: "flex-start",
            transition: 'width 0.1s ease',
          }}>
            { this.state.loading ? 
            <Box style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'center'}}>
              <CircularProgress />
            </Box> 
            : 
            <>
            <Box style={{width:'100%', display:'flex', justifyContent:'center',}}>
              <Box style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                marginTop:'25px',
                marginRight:'21px',
                width:'239px',
                height:'40px',
                transition: 'all 0.1s ease',
              }}>
                <HoverButton style={{ width: '40px' }} >
                  <Box onClick={this.isOpen} data-test-id="hamburgerId" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.isOpen
                      ? <img src={hamburger_icon_selected} style={{ width: '40px', height: '40px' }} />
                      : <Box onClick={this.isOpen} style={{ width: '24px', height: '24px' }}>
                        <MenuRoundedIcon style={{ color: '#C5CBC9' }} />
                      </Box>
                    }
                  </Box>
                </HoverButton>
                <Box>
                  <img src={logo} style={{ width: '51.69px', height: '24px' }} />
                </Box>
              </Box>
            </Box>
            {this.mainSideBarContent()}
            </>
            }
          </Box>
        </Drawer>
      </Box>
    )
  }

  renderOverlay = () => {
    if (!this.state.isOpen) return null;

    return (
      <Box
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: this.state.isOpen ? 'rgba(1, 61, 79, 0.4)' : 'rgba(0, 0, 0, 0)',
          zIndex: 1100,
          transition: 'background-color 0.1s ease-in-out',
        }}
        onClick={this.isOpen} 
      />
    );
  }

  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <HamburgerMenuStyle>
            <Box onClick={this.isOpen} style={{ width: '24px', height: '24px' }}>
                <MenuRoundedIcon style={{ color: '#C5CBC9' }} />
            </Box>
          {this.renderHamburgerMenu()}
        </HamburgerMenuStyle>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const HamburgerMenuStyle = styled(Box)({
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: '#C5CBC9',
        opacity: 1,
        fontWeight:400,
        fontSize:'14px'
      },
    },
  })

  const HoverButton = styled(ButtonBase)({
    width: '100%',
    '&:hover': {
      backgroundColor: '#E8F8FD',
    },
  });
// Customizable Area End
