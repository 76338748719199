import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area   Start
export const configJSON = require("./config");
import { getStorageData} from "../../../framework/src/Utilities";

export interface SpReviews {
  spName : string;
  likes : number,
  dislikes : number,
  reviewsArray : ReviewsArray[],
}

export interface ReviewsArray {
  id?: string;
  type?: string;
  attributes: {
    id: number;
    rating: boolean;
    feedback: string;
    created_at: string;
    service_name: string;
    account: {
      full_name: string;
      profile_picture: string | undefined;
    };
    dealer_name: string;
    initials : string;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string,
  dropdownData: any[];
  data: any[],
  closeDrawer: boolean;
  selectedCardService: any;
  searchValue: any,
  sortType: string,
  currentPage: number,
  totalpages: number,
  checked: any,
  spId : number | null,
  spStaticName : string,
  spReviews : SpReviews,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServiceProviderDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFetchData = "";
  advancedsearchApiCallId: any;
  getSpReviewsCallId  : string = "";
  spTestCallId : string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      data: [],
      closeDrawer: false,
      selectedCardService: [],
      searchValue: '',
      sortType: "",
      dropdownData: [],
      currentPage: 1,
      totalpages: 0,
      checked: {},
      spId : null,
      spStaticName : '',
      spReviews : {
        spName : '',
        likes : 0,
        dislikes : 0,
        reviewsArray: [
          {
            id: '',
            type: '',
          attributes: {
            id: 0,
            rating: false,
            feedback: '',
            created_at: '',
            service_name: '',
            account: {
              full_name: '',
              profile_picture: '',
            },
            dealer_name: '',
            initials: '',
          }
        }
        ]
      }
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({ currentPage: 1 })
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    const stateCode = localStorage.getItem("state_code")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getServiceProviderListApiEndPoint}?state_code=${stateCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);
      const authToken = localStorage.getItem("authToken")
      this.setState({ token: authToken || '' })
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      runEngine.debugLog("API Message Recived", message);
      if (apiCallId === this.advancedsearchApiCallId) {
      if (!responseJson?.errors && responseJson?.service_providers?.data && responseJson?.service_providers?.data?.length > 0) {
        this.setState({ data: responseJson && responseJson?.service_providers && responseJson?.service_providers?.data, totalpages: responseJson?.total_pages });
      } 
      else {
        this.setState({data: [], currentPage: 0, totalpages: 0})
      } 
    }

      if (apiCallId === this.getSpReviewsCallId){
        this.setState((prevState) => ({
          spReviews: {
            ...prevState.spReviews,
            spName : responseJson?.reviews?.data[0]?.attributes?.service_name,
            likes : responseJson?.likes,
            dislikes : responseJson?.dislikes,
            reviewsArray : responseJson?.reviews?.data,
          },
        }));
      }

      if (apiCallId === this.spTestCallId)
        {
          this.showServiceName('full_service')
          this.getInitials("sama");
          this.showServiceName('onsite')
          this.setSpId(12);
          this.showServiceName('white_glove')
          this.getInitials("");
          this.showServiceName('runner')
        }

      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getInitials = (profileName: string) => { 
    if (!profileName) return '';
    
    const nameArray = profileName.trim().split(' ');
    const derivedInitials = nameArray
      .map(name => name.charAt(0))
      .join('')
      .slice(0, 2)
      .toUpperCase(); 
    return derivedInitials; 
  } 

  setSpId = (id : number) => {
    this.setState({
      spId : id,
    })
  }

  showServiceName = (name : string) => {
    if (name === 'runner') return 'Runner Service';
    else if (name === 'full_service') return 'Full Service';
    else if (name === 'white_glove') return 'White Glove';
    else if (name === 'onsite') return 'Onsite';
  }

  selectServiceHandler = (provider: any) => {
    const finalData = this.state.data && this.state.data.map((item) => {
      if (item.id === provider.id) {
        return {
          ...item,
          buttonIndex: provider.buttonIndex
        }
      } else {
        return item
      }
    }
    )
    this.setState({ 
      data: finalData, 
      selectedCardService: [...this.state.selectedCardService, provider] })
  }

  DrawerHandler = (id : number | null, spName : string) => {
    this.setState({ 
      closeDrawer: !this.state.closeDrawer,
      spId : id, 
      spStaticName : spName,
    })
    
    this.getSpReviews(id)
  }

  getSpReviews = async (id : number | null) => {
    let tokenn = await getStorageData("authToken");
    const Webheader = {"token" : tokenn};
    const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSpReviewsCallId = 
    WebrequestMessage.messageId;
    WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpReviewApiEndPoint+'?service_provider_information_id='+`${id}`);
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader));
    WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod);
    runEngine.sendMessage(
      WebrequestMessage.id, 
      WebrequestMessage
    );
  }

  removeSelectedServiceHandler = (provider: any) => {
    const data = this.state.selectedCardService && this.state.selectedCardService.filter((item: any) => item.id !== provider.id)
    const finalData = this.state.data && this.state.data.map((item) => {
      if (provider.id === item.id) {
        const { buttonIndex, ...rest } = item
        return {
          ...rest
        }
      } else {
        return item
      }
    }
    )
    this.setState({ data: finalData, selectedCardService: data })
  }

  removeSelectedCardsFromServiceBlock = (cardItem: any) => {
    const finalData = this.state.selectedCardService && this.state.selectedCardService.filter((item: any) => item.id !== cardItem.id)
    const data = this.state.data && this.state.data.map((item) => {
      if (cardItem.id === item.id) {
        const { buttonIndex, ...rest } = item
        return rest
      } else {
        return item
      }
    }
    )
    this.setState({ data: data, selectedCardService: finalData })
  }

  getServiceProviderList = (searchValue: string, sortType: string, multipleServiceSort: any, page: number) => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    const stateCode = localStorage.getItem("state_code")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getServiceProviderListApiEndPoint}?state_code=${stateCode}&search=${searchValue}&sort=${sortType}&provider_services=${multipleServiceSort}&page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchServiceListHandler = (event: any, type: string) => {
    const value = event.target.value as string;
    const { searchValue, sortType, dropdownData, currentPage } = this.state;
    if (type === 'sort') {
      this.setState({ sortType: event.target.value })
      this.getServiceProviderList(searchValue, value, dropdownData, currentPage);
    } else if (type === 'search') {
      this.setState({ searchValue: event.target.value })
      this.getServiceProviderList(value, sortType, dropdownData, currentPage);
    }
  }

  handleChangeDropdown = (event: any) => {
    const { searchValue, sortType, currentPage } = this.state
    const value: any = event.target.value;
    this.setState({
      dropdownData: typeof value === 'string' ? value.split(',') : value
    });

    this.getServiceProviderList(searchValue, sortType, value, currentPage)

  };

  handlePageChange = (event: any, value: any) => {
    const { searchValue, sortType, dropdownData } = this.state
    this.setState({ currentPage: value });
    this.getServiceProviderList(searchValue, sortType, dropdownData, value)
  };

  handleChecked = (key: any) => {
    if (this.state.checked.hasOwnProperty(key)) {
      const {[key]: removed, ...rest } = this.state.checked
      this.setState({ checked: rest })
    } else {
      this.setState({ checked: { ...this.state.checked, [key]: key } })
    }
  }

  navigateToScreen = (screen: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
  }

  backNavigation = () => {
    this.navigateToScreen('ProjectTemplatesDealDashboard');
  }
  // Customizable Area End
}