// Customizable Area Start
import React, { CSSProperties } from "react";

import {
  Box,
  Avatar,
  Typography,
  styled,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";

import DealershipsTableController, {
  Props,
} from "./DealershipsTableController.web";
import {
  ContentView,
  DealershipNormalized,
  ServiceableLocation,
} from "./MyTeamController.web";
import moment from "moment";
import { TruncatedList } from "../../../components/src/TruncateList";

export default class DealershipsTable extends DealershipsTableController {
  constructor(props: Props) {
    super(props);
  }
  backgroundItemChecked = (item: any) =>
    item.checked ? "#F0FAFA" : "transparent";
  styledAvatarChecked = (item: any) => (item.checked ? "#fff" : "#f7fcfc");
  styledAvatarGetName = (item: any) =>
    item.name
      ? item.name
          .trim()
          .split(" ")
          .filter((word: any) => word !== "")
          .map((word: any) => word[0].toUpperCase())
          .join("")
          .slice(0, 2)
      : "";

  render() {
    const {
      data,
      isAdmin,
      handleDealershipEdit,
      showDealershipUsers,
      view,
      showLocationUsers,
    } = this.props;
    const currentPageData = data.slice();
    const tableRowClass = isAdmin() ? "tableRow admin" : "tableRow user";
    return (
      <StyledContainer>
        <Table>
          <TableHead>
            <TableRow className={tableRowClass}>
              {view === ContentView.DEALERSHIPS ? (
                <TableCell className="thCell">Dealerships</TableCell>
              ) : (
                <>
                  <TableCell className="thCell">State</TableCell>
                  <TableCell className="thCell">Services</TableCell>
                </>
              )}
              <TableCell className="thCell">Users</TableCell>
              <TableCell className="thCell">Created on</TableCell>
              {isAdmin() && <TableCell className="thCell">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {view === ContentView.DEALERSHIPS &&
              currentPageData.map((item: DealershipNormalized, i: number) => (
                <TableRow
                  key={item.id}
                  className={tableRowClass}
                  style={{
                    backgroundColor: this.backgroundItemChecked(item),
                  }}
                >
                  <TableCell
                    className="trCell"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <StyledAvatar
                      data-test-id="dealership-avatar"
                      src={item.logo}
                      alt={item.name}
                      variant="rounded"
                      style={{
                        backgroundColor: this.styledAvatarChecked(item),
                      }}
                    >
                      {this.styledAvatarGetName(item)}
                    </StyledAvatar>
                    <Typography
                      onClick={() => {
                        showDealershipUsers && showDealershipUsers(item.id);
                      }}
                      data-test-id={`dealership-${i}`}
                      className="name"
                    >
                      {item.name}
                    </Typography>
                  </TableCell>
                  <TableCell className="trCell">{item.users}</TableCell>
                  <TableCell className="trCell">
                    {moment
                      .utc(item.createdAt)
                      .local()
                      .format("MMMM D, YYYY h:mm a")}
                  </TableCell>
                  {isAdmin() && (
                    <TableCell className="trCell">
                      <Typography
                        data-test-id={`edit-dealership-${i}`}
                        className="actionButton"
                        onClick={() => handleDealershipEdit && handleDealershipEdit(item.id)}
                      >
                        Edit
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}

            {view === ContentView.SERVICEABLE_LOCATIONS &&
              currentPageData.map((item: ServiceableLocation, i: number) => (
                <TableRow
                  key={item.id}
                  className={tableRowClass}
                  style={{
                    backgroundColor: this.backgroundItemChecked(item),
                  }}
                >
                  <TableCell
                    className="trCell"
                    style={{
                      justifyContent: "flex-start",
                      gap: "12px",
                    }}
                  >
                    <Typography
                      onClick={() => {
                        showLocationUsers && showLocationUsers(item.id);
                      }}
                      data-test-id={`dealership-${i}`}
                      className="name"
                    >
                      {item.provider_state}
                    </Typography>
                  </TableCell>
                  <TableCell className="trCell">
                    <TruncatedList items={item.provider_services.toString().split(',') } visibleCount={2}></TruncatedList>
                  </TableCell>
                  <TableCell className="trCell">{item.users_count}</TableCell>
                  <TableCell className="trCell">
                    {moment
                      .utc(item.created_at)
                      .local()
                      .format("MMMM D, YYYY h:mm a")}
                  </TableCell>
                  <TableCell className="trCell">
                  {(isAdmin() && item.is_loaction_created_by_login_user) ? (
                    <Typography
                      data-test-id={`edit-dealership-${i}`}
                      className="actionButton"
                      onClick={() =>
                        this.props.handleServiceableLocationEdit &&
                        this.props.handleServiceableLocationEdit(item.id)
                      }
                    >
                      Edit
                    </Typography>
                    ) : <Typography></Typography>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "& .thCell": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#C5CBC9",
  },

  "& .trCell": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "#4B4C4B",
  },

  "& .name": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    cursor: "pointer",
  },

  "& .actionButton": {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 500,
    cursor: "pointer",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "48px",
  height: "48px",
  borderRadius: "12px",
  backgroundColor: "#f7fcfc",
  color: "#4B4C4B",
}));

// Customizable Area End
