export const CA_BASE_RECORD = {
    "locationId": 2,
    "serviceType": "FeeInquiry",
    "state": "CA",
    "applicationTypeId": "0",
    "saleTypeId": "0",
    "sellerPermitNumber": "123123123",
    "feeExemption": false,
    "leaseBuyout": {
        "isLeaseBuyout": false
    },
    "repossession": false,
    "useTypeId": "0",
    "plateCategoryId": "0",
    "noTitle": true,
    "paperlessTitle": false,
    "isReturnedToState": false,
    "isPermanentResident": true,
    "isMemberOfMilitary": false,
    "sameFranchise": false,
    "seller": {
        "isIndividual": false,
        "isNotMyDealership": false
    },
    "subPlate": false,
    "subSticker": false
}
