
import React, { useEffect, useRef } from "react";
import Modal from "react-modal";

type DynamicReviewModalElement = HTMLElement & {
  record: any;
  dynamicFieldList: any[];
  applicationTypeName: string;
  reviewJsonResponse: any;
}

export default function ReviewModal({
  modalOpen,
  record,
  applicationTypeName,
  dynamicFieldList,
  reviewJsonResponse,
  onSubmit,
  onCancel,
}: {
  modalOpen: boolean,
  record: any,
  applicationTypeName: string,
  dynamicFieldList: any[],
  reviewJsonResponse: any,
  onSubmit: (forms: any[]) => void,
  onCancel: () => void,
}) {

  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<DynamicReviewModalElement | null>(null);

  const reviewResultChangeHandler = (event: Event) => {
      const result = ((event as CustomEvent<any>).detail);
      if (result.status === "CANCELED") onCancel();
      if (result.status === "SUBMITTED") onSubmit(result.formList);
  };

  const afterOpenModal = () => {

      const ref = modalContainerRef;

      if (ref) {

          const modalElement = document.createElement("review-modal") as DynamicReviewModalElement;
          modalElement.record = record;
          modalElement.dynamicFieldList = dynamicFieldList;
          modalElement.applicationTypeName = applicationTypeName;
          modalElement.reviewJsonResponse = reviewJsonResponse;

          modalElement.addEventListener("reviewResultChange", reviewResultChangeHandler);

          ref.current!.appendChild(modalElement);
          modalRef.current = modalElement;
      }
  }

  const afterCloseModal = () => {

      modalRef.current!.remove();
      modalRef.current!.removeEventListener("reviewResultChange", reviewResultChangeHandler);
      modalRef.current = null;
  }

  return <Modal isOpen={modalOpen || false}
      onAfterOpen={afterOpenModal}
      onAfterClose={afterCloseModal}
      ariaHideApp={false}>
      <div ref={modalContainerRef}></div>
  </Modal>
}
