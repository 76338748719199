import React from "react";
import { Tooltip, Typography } from "@material-ui/core";

interface ListProps {
  items: string[];
  visibleCount: number;
}

export const TruncatedList: React.FC<ListProps> = ({ items, visibleCount }) => {
  // Function to generate the truncated list
  const getTruncatedList = (items: string[], visibleCount: number) => {
    if (items.length <= visibleCount) return items;
    const remainingCount = items.length - visibleCount;
    return [...items.slice(0, visibleCount), `+${remainingCount} more`];
  };

  const truncatedItems = getTruncatedList(items, visibleCount);

  return (
    <div>
      {truncatedItems.map((item, index) => {
        const isMoreItem = item.startsWith("+") && item.includes("more");
        return (
          <React.Fragment key={index}>
            {isMoreItem ? (
              <Tooltip
                title={
                  <div>
                    {items.splice(visibleCount).map((fullItem, idx) => (
                      <Typography key={idx} variant="body2">
                        {fullItem}
                      </Typography>
                    ))}
                  </div>
                }
                arrow
              >
                <span
                  style={{
                    cursor: "pointer",
                    color: "#013D4F"
                  }}
                >
                  {item}
                </span>
              </Tooltip>
            ) : (
              <span>{item}</span>
            )}
            {index < truncatedItems.length - 1 && ", "}
          </React.Fragment>
        );
      })}
    </div>
  );
};
