export const CA_DYNAMIC_FIELDS = [
    {
        "name": "vin",
        "title": "VIN",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": true,
        "type": "VIN",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "vinParams": {
            "titleExpression": "vehicleTypeId == '8' ? 'HIN' : 'VIN'"
        },
        "line": 0
    },
    {
        "name": "vehicleTypeId",
        "title": "Vehicle Type",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "lookup": [
            {
                "code": "0",
                "name": "Passenger"
            },
            {
                "code": "1",
                "name": "Motorcycle"
            },
            {
                "code": "2",
                "name": "Moped"
            },
            {
                "code": "3",
                "name": "Truck"
            },
            {
                "code": "4",
                "name": "Commercial Vehicle"
            },
            {
                "code": "5",
                "name": "Trailer"
            },
            {
                "code": "6",
                "name": "Motorhome"
            },
            {
                "code": "7",
                "name": "OHV"
            },
            {
                "code": "8",
                "name": "Vessel"
            }
        ],
        "line": 0
    },
    {
        "name": "make",
        "title": "Make",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": false,
        "type": "TypeAhead",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "requiredExpression": "true",
        "typeAheadSource": "MakeByContainsJson",
        "line": 0
    },
    {
        "name": "model",
        "title": "Model",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": false,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "requiredExpression": "true",
        "line": 0
    },
    {
        "name": "bodyTypeId",
        "title": "Body Type",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": false,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "requiredExpression": "true",
        "lookup": [
            {
                "code": "32",
                "name": "Auto cary (AC)"
            },
            {
                "code": "33",
                "name": "Backhoe (BH)"
            },
            {
                "code": "34",
                "name": "Bevrg rck (BR)"
            },
            {
                "code": "35",
                "name": "Boat trlr (BT)"
            },
            {
                "code": "36",
                "name": "Bottm dmp (DB)"
            },
            {
                "code": "37",
                "name": "Brsh chpr (BC)"
            },
            {
                "code": "38",
                "name": "Buggy (BG)"
            },
            {
                "code": "39",
                "name": "Bulldozer (BD)"
            },
            {
                "code": "40",
                "name": "Bus (BU)"
            },
            {
                "code": "41",
                "name": "Cab chass (CB)"
            },
            {
                "code": "42",
                "name": "Cabl reel (CL)"
            },
            {
                "code": "43",
                "name": "Cabriolet (CA)"
            },
            {
                "code": "44",
                "name": "Camper (KT)"
            },
            {
                "code": "45",
                "name": "Camping (CT)"
            },
            {
                "code": "46",
                "name": "Canopy ex (CE)"
            },
            {
                "code": "47",
                "name": "Cargo (A1)"
            },
            {
                "code": "48",
                "name": "Carryall (LL)"
            },
            {
                "code": "49",
                "name": "Cnst trac (TC)"
            },
            {
                "code": "50",
                "name": "Cnvtr ger (CG)"
            },
            {
                "code": "51",
                "name": "Coach (CH)"
            },
            {
                "code": "52",
                "name": "Combine (CO)"
            },
            {
                "code": "53",
                "name": "Concr mixer (CM)"
            },
            {
                "code": "54",
                "name": "Convertbl (CV)"
            },
            {
                "code": "55",
                "name": "Coupe (CP)"
            },
            {
                "code": "56",
                "name": "Crane (CR)"
            },
            {
                "code": "57",
                "name": "Crew pk (CW)"
            },
            {
                "code": "58",
                "name": "Crew pkup (CY)"
            },
            {
                "code": "59",
                "name": "Cutaway (YY)"
            },
            {
                "code": "60",
                "name": "Delivery (DE)"
            },
            {
                "code": "61",
                "name": "Depot hck (DH)"
            },
            {
                "code": "62",
                "name": "Dirt bike (DM)"
            },
            {
                "code": "63",
                "name": "Dlx dl tk (DX)"
            },
            {
                "code": "64",
                "name": "Dolly (DY)"
            },
            {
                "code": "65",
                "name": "Drop flat (DF)"
            },
            {
                "code": "66",
                "name": "Dsply van (VD)"
            },
            {
                "code": "67",
                "name": "Dump tk (DP)"
            },
            {
                "code": "68",
                "name": "Dump trlr (HD)"
            },
            {
                "code": "69",
                "name": "Dune bugy (DN)"
            },
            {
                "code": "70",
                "name": "Electric (EL)"
            },
            {
                "code": "71",
                "name": "Enduro (EN)"
            },
            {
                "code": "72",
                "name": "Ext sptvn (ES)"
            },
            {
                "code": "73",
                "name": "Ext van (EV)"
            },
            {
                "code": "74",
                "name": "Ext van (EC)"
            },
            {
                "code": "75",
                "name": "Ext winvn (EW)"
            },
            {
                "code": "76",
                "name": "Farm eqip (MF)"
            },
            {
                "code": "77",
                "name": "Farm trac (TF)"
            },
            {
                "code": "78",
                "name": "Fastback (FK)"
            },
            {
                "code": "79",
                "name": "Fertilizr (FS)"
            },
            {
                "code": "80",
                "name": "Fire app (FT)"
            },
            {
                "code": "81",
                "name": "Fire app (FA)"
            },
            {
                "code": "82",
                "name": "Flatbed (FB)"
            },
            {
                "code": "83",
                "name": "Flatrack (FR)"
            },
            {
                "code": "84",
                "name": "Flip axle (FX)"
            },
            {
                "code": "85",
                "name": "Forklift (FL)"
            },
            {
                "code": "86",
                "name": "Fowrd cont (FC)"
            },
            {
                "code": "87",
                "name": "Garbage tk (GG)"
            },
            {
                "code": "88",
                "name": "Generator (GE)"
            },
            {
                "code": "89",
                "name": "Glass rak (GR)"
            },
            {
                "code": "90",
                "name": "Glider (GL)"
            },
            {
                "code": "91",
                "name": "Go kart (GK)"
            },
            {
                "code": "92",
                "name": "Golf cart (GC)"
            },
            {
                "code": "93",
                "name": "Gondola (GA)"
            },
            {
                "code": "94",
                "name": "Grader (GD)"
            },
            {
                "code": "95",
                "name": "Grain/box (GN)"
            },
            {
                "code": "96",
                "name": "Hardtop (HT)"
            },
            {
                "code": "97",
                "name": "Hardtop cv (RH)"
            },
            {
                "code": "98",
                "name": "Hatchback (HB)"
            },
            {
                "code": "99",
                "name": "Hearse (HR)"
            },
            {
                "code": "100",
                "name": "Hopper (HO)"
            },
            {
                "code": "101",
                "name": "Horse trl (HE)"
            },
            {
                "code": "102",
                "name": "Inc chass (IC)"
            },
            {
                "code": "103",
                "name": "Inc extvn (IE)"
            },
            {
                "code": "104",
                "name": "Jeepster (JP)"
            },
            {
                "code": "105",
                "name": "Limousine (LM)"
            },
            {
                "code": "106",
                "name": "Livestock (LS)"
            },
            {
                "code": "107",
                "name": "Loader (LD)"
            },
            {
                "code": "108",
                "name": "Logger (LG)"
            },
            {
                "code": "109",
                "name": "Logskiddr (KLK)"
            },
            {
                "code": "110",
                "name": "Low speed (LV)"
            },
            {
                "code": "111",
                "name": "Lowboy (LB)"
            },
            {
                "code": "112",
                "name": "Lunch wgn (LW)"
            },
            {
                "code": "113",
                "name": "Maxi van (MV)"
            },
            {
                "code": "114",
                "name": "Mini bike (MK)"
            },
            {
                "code": "115",
                "name": "Mini cycl (MY)"
            },
            {
                "code": "116",
                "name": "Mini moto (MM)"
            },
            {
                "code": "117",
                "name": "Mini road (MR)"
            },
            {
                "code": "118",
                "name": "Misc (XX)"
            },
            {
                "code": "119",
                "name": "Mobile hm (HS)"
            },
            {
                "code": "120",
                "name": "Moble off (MB)"
            },
            {
                "code": "121",
                "name": "Moped (MD)"
            },
            {
                "code": "122",
                "name": "Moped (MP)"
            },
            {
                "code": "123",
                "name": "Moto cros (MX)"
            },
            {
                "code": "124",
                "name": "Motor hom (MH)"
            },
            {
                "code": "127",
                "name": "Mtr scoot (MS)"
            },
            {
                "code": "128",
                "name": "Mtrcl 3wl (MW)"
            },
            {
                "code": "129",
                "name": "Mtrcy trl (MT)"
            },
            {
                "code": "130",
                "name": "Notchback (NB)"
            },
            {
                "code": "131",
                "name": "One seat (S1)"
            },
            {
                "code": "132",
                "name": "Open body (OP)"
            },
            {
                "code": "133",
                "name": "Opera cp (OC)"
            },
            {
                "code": "134",
                "name": "Pallet (PL)"
            },
            {
                "code": "135",
                "name": "Panel (PN)"
            },
            {
                "code": "136",
                "name": "Parcl dlv (PD)"
            },
            {
                "code": "137",
                "name": "Park modl (PA)"
            },
            {
                "code": "138",
                "name": "Pass car (PC)"
            },
            {
                "code": "139",
                "name": "Phaeton (PH)"
            },
            {
                "code": "140",
                "name": "Pickup (PK)"
            },
            {
                "code": "141",
                "name": "Pickup rv (PM)"
            },
            {
                "code": "142",
                "name": "Pk camper (PR)"
            },
            {
                "code": "143",
                "name": "Pole/pipe (LP)"
            },
            {
                "code": "144",
                "name": "Pumper (PP)"
            },
            {
                "code": "145",
                "name": "Racer cyc (RC)"
            },
            {
                "code": "146",
                "name": "Reefer (RF)"
            },
            {
                "code": "147",
                "name": "Reglr cab (CC)"
            },
            {
                "code": "148",
                "name": "Road/tral (RT)"
            },
            {
                "code": "149",
                "name": "Roadster (RD)"
            },
            {
                "code": "150",
                "name": "Roller (RO)"
            },
            {
                "code": "151",
                "name": "Runabout (RU)"
            },
            {
                "code": "152",
                "name": "Scraper (SC)"
            },
            {
                "code": "153",
                "name": "Sedan (SD)"
            },
            {
                "code": "154",
                "name": "Sedan del (NM)"
            },
            {
                "code": "217",
                "name": "4 DR Extended Cab Pickup (4C)"
            },
            {
                "code": "218",
                "name": "Amphibian (AMPH)"
            },
            {
                "code": "219",
                "name": "Camp (CMP)"
            },
            {
                "code": "220",
                "name": "Carnival Equipment (CARN)"
            },
            {
                "code": "221",
                "name": "Carrier (CARRIER)"
            },
            {
                "code": "222",
                "name": "Cement Mixer (MIXER)"
            },
            {
                "code": "223",
                "name": "Coach (CCH)"
            },
            {
                "code": "224",
                "name": "Coach Boat (CCHBT)"
            },
            {
                "code": "225",
                "name": "Dolly (DLY)"
            },
            {
                "code": "226",
                "name": "Dump (DMP)"
            },
            {
                "code": "227",
                "name": "Fire (FIRE)"
            },
            {
                "code": "228",
                "name": "Modular Mobile (MMETLR)"
            },
            {
                "code": "229",
                "name": "Multiple Bodies (MULT)"
            },
            {
                "code": "230",
                "name": "Park Trailer (CCHPT)"
            },
            {
                "code": "231",
                "name": "Park Trailer Boat (CCHPTBT)"
            },
            {
                "code": "232",
                "name": "Refrigerated (REF)"
            },
            {
                "code": "233",
                "name": "Tank (TANK)"
            },
            {
                "code": "234",
                "name": "Transit Mix (TRMX)"
            },
            {
                "code": "235",
                "name": "Utility (UTIL)"
            },
            {
                "code": "236",
                "name": "Van (VAN)"
            },
            {
                "code": "237",
                "name": "Varied (VARIED)"
            },
            {
                "code": "238",
                "name": "3wheel dr (3W)"
            },
            {
                "code": "239",
                "name": "Amphibian (AP)"
            },
            {
                "code": "240",
                "name": "Dirt (T_)"
            },
            {
                "code": "125",
                "name": "Motorcycle (MC)"
            },
            {
                "code": "126",
                "name": "Motorcycle (RS)"
            },
            {
                "code": "1",
                "name": "2 door (2D)"
            },
            {
                "code": "2",
                "name": "2c (2C)"
            },
            {
                "code": "3",
                "name": "2d hachbk (2H)"
            },
            {
                "code": "4",
                "name": "2d hardtp (2F)"
            },
            {
                "code": "5",
                "name": "2d hardtp (2T)"
            },
            {
                "code": "6",
                "name": "2d hardtp (2P)"
            },
            {
                "code": "7",
                "name": "2d hardtp (3D)"
            },
            {
                "code": "8",
                "name": "2d liftbk (2L)"
            },
            {
                "code": "9",
                "name": "2d sedan (2S)"
            },
            {
                "code": "10",
                "name": "3 door (3P)"
            },
            {
                "code": "11",
                "name": "3d liftbk (3L)"
            },
            {
                "code": "12",
                "name": "3dr extcc (3C)"
            },
            {
                "code": "13",
                "name": "3dr extcp (3B)"
            },
            {
                "code": "14",
                "name": "4 door (4D)"
            },
            {
                "code": "15",
                "name": "4d hardtp (4T)"
            },
            {
                "code": "16",
                "name": "4d hardtp (4P)"
            },
            {
                "code": "17",
                "name": "4d htchbk (4H)"
            },
            {
                "code": "18",
                "name": "4d liftbk (4L)"
            },
            {
                "code": "19",
                "name": "4d sedan (4S)"
            },
            {
                "code": "20",
                "name": "4d sedan (5D)"
            },
            {
                "code": "21",
                "name": "4dr (C4)"
            },
            {
                "code": "22",
                "name": "4dr extcc (4B)"
            },
            {
                "code": "23",
                "name": "4wheel dr (4W)"
            },
            {
                "code": "24",
                "name": "5d htchbk (5H)"
            },
            {
                "code": "25",
                "name": "8pas spvn (8V)"
            },
            {
                "code": "26",
                "name": "Air comp (AI)"
            },
            {
                "code": "27",
                "name": "All terrn (AT)"
            },
            {
                "code": "28",
                "name": "Ambulance (AM)"
            },
            {
                "code": "29",
                "name": "Amphibian (AV)"
            },
            {
                "code": "30",
                "name": "Armord tk (AR)"
            },
            {
                "code": "31",
                "name": "Atv (AL)"
            },
            {
                "code": "155",
                "name": "Sedan del (SL)"
            },
            {
                "code": "156",
                "name": "Semi trl (SE)"
            },
            {
                "code": "157",
                "name": "Shovel (SH)"
            },
            {
                "code": "158",
                "name": "Sidecar (SR)"
            },
            {
                "code": "159",
                "name": "Skdstrldr (SK)"
            },
            {
                "code": "160",
                "name": "Sngl whel (1W)"
            },
            {
                "code": "161",
                "name": "Snobl tl (SM)"
            },
            {
                "code": "162",
                "name": "Snow rmvl (SO)"
            },
            {
                "code": "163",
                "name": "Sp equip (SQ)"
            },
            {
                "code": "164",
                "name": "Sp-constd (SX)"
            },
            {
                "code": "165",
                "name": "Speedster (SZ)"
            },
            {
                "code": "166",
                "name": "Sport trk (SP)"
            },
            {
                "code": "167",
                "name": "Sport van (SV)"
            },
            {
                "code": "168",
                "name": "Sport van (VA)"
            },
            {
                "code": "169",
                "name": "Sporttrac (E1)"
            },
            {
                "code": "170",
                "name": "St sweepr (SS)"
            },
            {
                "code": "171",
                "name": "Stake/rak (ST)"
            },
            {
                "code": "172",
                "name": "Step van (SN)"
            },
            {
                "code": "173",
                "name": "Stn wagon (SW)"
            },
            {
                "code": "174",
                "name": "Supcab pk (PS)"
            },
            {
                "code": "175",
                "name": "Supercab (CS)"
            },
            {
                "code": "176",
                "name": "Tandem (TA)"
            },
            {
                "code": "177",
                "name": "Tanker (TN)"
            },
            {
                "code": "178",
                "name": "Tent tlr (TE)"
            },
            {
                "code": "179",
                "name": "Tilt cab (TB)"
            },
            {
                "code": "180",
                "name": "Tilt tand (TM)"
            },
            {
                "code": "181",
                "name": "Tourngcar (TO)"
            },
            {
                "code": "182",
                "name": "Tow dolly (TD)"
            },
            {
                "code": "183",
                "name": "Tow truck (TW)"
            },
            {
                "code": "184",
                "name": "Tow truck (TT)"
            },
            {
                "code": "185",
                "name": "Tow truck (WK)"
            },
            {
                "code": "186",
                "name": "Tractr tk (TU)"
            },
            {
                "code": "187",
                "name": "Trailer (TL)"
            },
            {
                "code": "188",
                "name": "Travelall (XT)"
            },
            {
                "code": "189",
                "name": "Travelall (WW)"
            },
            {
                "code": "190",
                "name": "Travl trl (TV)"
            },
            {
                "code": "191",
                "name": "Trencher (TH)"
            },
            {
                "code": "193",
                "name": "Trike (TI)"
            },
            {
                "code": "194",
                "name": "Trkcamper (TP)"
            },
            {
                "code": "195",
                "name": "Trl truck (DS)"
            },
            {
                "code": "196",
                "name": "Trlr jcky (TJ)"
            },
            {
                "code": "197",
                "name": "Truck (TK)"
            },
            {
                "code": "198",
                "name": "Truckster (TS)"
            },
            {
                "code": "199",
                "name": "Tudor (UD)"
            },
            {
                "code": "200",
                "name": "Two seat (S2)"
            },
            {
                "code": "201",
                "name": "Two wheel (2W)"
            },
            {
                "code": "202",
                "name": "Utility (WG)"
            },
            {
                "code": "203",
                "name": "Utility (AW)"
            },
            {
                "code": "204",
                "name": "Utility (CU)"
            },
            {
                "code": "205",
                "name": "Utility (UT)"
            },
            {
                "code": "206",
                "name": "Van (VN)"
            },
            {
                "code": "207",
                "name": "Van campr (VC)"
            },
            {
                "code": "208",
                "name": "Vanette (VT)"
            },
            {
                "code": "209",
                "name": "Wagon (WA)"
            },
            {
                "code": "210",
                "name": "Welder (WE)"
            },
            {
                "code": "211",
                "name": "Well dril (WD)"
            },
            {
                "code": "212",
                "name": "Whl loadr (WL)"
            },
            {
                "code": "213",
                "name": "Winch (WI)"
            },
            {
                "code": "214",
                "name": "Window vn (VW)"
            },
            {
                "code": "215",
                "name": "Xuv (XV)"
            },
            {
                "code": "216",
                "name": "Yard trac (MO)"
            },
            {
                "code": "192",
                "name": "Trails (TR)"
            },
            {
                "code": "241",
                "name": "Privately Owned School Bus (SB)"
            },
            {
                "code": "242",
                "name": "Refrigerated (RE)"
            },
            {
                "code": "243",
                "name": "Wrecker with Flatbed (WF)"
            },
            {
                "code": "244",
                "name": "Neighborhood Electric Vehicle (NE)"
            }
        ],
        "line": 0
    },
    {
        "name": "year",
        "title": "Year",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Year",
        "minLength": 0,
        "maxValue": 2100,
        "minValue": 1900,
        "formControlSize": 1,
        "line": 0
    },
    {
        "name": "unladenWeight",
        "title": "Unladen Weight",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "maxValue": 100000,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '4' || vehicleTypeId == '3' || vehicleTypeId == '2' || vehicleTypeId == '0' || vehicleTypeId == '1' || vehicleTypeId == '6')",
        "line": 0
    },
    {
        "name": "gvw",
        "title": "Gross Vehicle Weight",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": false,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '3' || vehicleTypeId == '4' || vehicleTypeId == '6')||useTypeId == '1'",
        "requiredExpression": "unladenWeight > 6000 || vehicleTypeId == '6'",
        "line": 0
    },
    {
        "name": "grossVehicleWeightRating",
        "title": "GVWR",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '4' || vehicleTypeId == '3') && (bodyTypeId == '140'|| bodyTypeId == '138' || bodyTypeId == '12' || bodyTypeId == '21') ",
        "line": 0
    },
    {
        "name": "fuelTypeId",
        "title": "Fuel Type",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '4' || vehicleTypeId == '3' || vehicleTypeId == '2' || vehicleTypeId == '0' || vehicleTypeId == '1' || vehicleTypeId == '6')",
        "lookup": [
            {
                "code": "8",
                "name": "Butane"
            },
            {
                "code": "1",
                "name": "Diesel"
            },
            {
                "code": "3",
                "name": "Electric"
            },
            {
                "code": "4",
                "name": "Flex Fuel"
            },
            {
                "code": "0",
                "name": "Gasoline"
            },
            {
                "code": "5",
                "name": "Hybrid (Diesel/Electric)"
            },
            {
                "code": "6",
                "name": "Hybrid (Gasoline/Electric)"
            },
            {
                "code": "7",
                "name": "Hydrogen Fuel Cell"
            },
            {
                "code": "9",
                "name": "Methanol"
            },
            {
                "code": "2",
                "name": "Natural Gas Powered"
            },
            {
                "code": "10",
                "name": "Propane"
            }
        ],
        "line": 0
    },
    {
        "name": "numberOfAxles",
        "title": "Number of Axles",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "vehicleTypeId == '4'",
        "line": 0
    },
    {
        "name": "length",
        "title": "Length (ft)",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '5' || vehicleTypeId == '6')",
        "line": 0
    },
    {
        "name": "width",
        "title": "Width (ft)",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '5' || vehicleTypeId == '6')",
        "line": 0
    },
    {
        "name": "caepa",
        "title": "Is this vehicle a CA-certified (50-state) vehicle?",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '0' || vehicleTypeId == '4' || vehicleTypeId == '2' || vehicleTypeId == '3' || vehicleTypeId == '7' || vehicleTypeId == '1' || vehicleTypeId == '6')",
        "line": 0
    },
    {
        "name": "deliverMethodId",
        "title": "Delivery Method",
        "section": "Sale Info",
        "required": true,
        "type": "Radio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(applicationTypeId != '1') && (((seller.isNotMyDealership != true) && ('4' == '4')) || ((seller.isNotMyDealership == true) && (seller.address.stateCode == 'CA')))",
        "lookup": [
            {
                "code": "0",
                "name": "Driven Off Lot"
            },
            {
                "code": "1",
                "name": "Vehicle Shipped"
            }
        ],
        "line": 0
    },
    {
        "name": "saleTypeId",
        "title": "Sale Type",
        "section": "Sale Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "lookup": [
            {
                "code": "0",
                "name": "Purchase"
            },
            {
                "code": "1",
                "name": "Lease"
            }
        ],
        "line": 0
    },
    {
        "name": "purchaseDate",
        "title": "Purchase Date",
        "section": "Sale Info",
        "required": true,
        "type": "Date",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "dateParams": {
            "minDate": "01/01/1900"
        },
        "line": 0
    },
    {
        "name": "soldAsId",
        "title": "Sold As",
        "section": "Sale Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "lookup": [
            {
                "code": "0",
                "name": "New"
            },
            {
                "code": "1",
                "name": "Used"
            }
        ],
        "line": 0
    },
    {
        "name": "sellingPrice",
        "title": "Selling Price",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 1.01,
        "formControlSize": 1,
        "onChangeExpression": "\n\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) +  (visibleRecord.documentFees || 0) +  (visibleRecord.addOns || 0);\n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "documentFees",
        "title": "Document Fees",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "onChangeExpression": "\n\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) +  (visibleRecord.documentFees || 0) +  (visibleRecord.addOns || 0);\n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "addOns",
        "title": "Add Ons",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "onChangeExpression": "\n\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) +  (visibleRecord.documentFees || 0) +  (visibleRecord.addOns || 0);\n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "taxableSellingPrice",
        "title": "Taxable Selling Price",
        "section": "Sale Info",
        "disabledExpression": "true",
        "required": false,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "true",
        "line": 0
    },
    {
        "name": "controlNumber",
        "title": "Control Number",
        "section": "Sale Info",
        "required": false,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "line": 0
    },
    {
        "name": "sellerPermitNumber",
        "title": "Seller Permit Number",
        "section": "Sale Info",
        "disabledExpression": "true",
        "required": false,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "validationExpression": "{ pattern: '[0-9]{9}', message: 'Must be 9 characters (numbers only)' }",
        "line": 0
    },
    {
        "name": "feeExemption",
        "title": "VLF fee tax exempt?",
        "section": "Sale Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "line": 0
    },
    {
        "name": "feeExemptionReasonId",
        "title": "Exemption Reason",
        "section": "Sale Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "feeExemption",
        "lookup": [
            {
                "code": "0",
                "name": "Non Resident Military"
            },
            {
                "code": "1",
                "name": "Specialized Transport"
            },
            {
                "code": "2",
                "name": "Tribal Owner"
            },
            {
                "code": "3",
                "name": "Disabled Veteran Owner"
            }
        ],
        "line": 0
    },
    {
        "name": "leaseBuyout.isLeaseBuyout",
        "title": "Is lease buyout?",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(saleTypeId == '0') && (soldAsId == '1')",
        "line": 0
    },
    {
        "name": "leaseBuyout.buyoutTypeId",
        "title": "Buyout Type",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)",
        "lookup": [
            {
                "code": "0",
                "name": "Leasing Company Direct"
            },
            {
                "code": "1",
                "name": "Third Party Dealership/Broker"
            }
        ],
        "line": 0
    },
    {
        "name": "leaseBuyout.leasingCompanyName",
        "title": "Leasing Company Name",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')",
        "line": 0
    },
    {
        "name": "repossession",
        "title": "Repossession?",
        "section": "Sale Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "soldAsId == '1'",
        "line": 0
    },
    {
        "name": "leaseBuyout.leasingCompanyAddress",
        "title": "Leasing Company Address",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "Address",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "addressParams": {
            "enableRemoteAddressVerification": false,
            "showAddressLine": true,
            "showAddressLine2": false,
            "disableStateCode": true,
            "disableCounty": true,
            "disableCity": true,
            "showCounty": true,
            "allowCrossStateZipCodesExpression": "true"
        },
        "visibilityExpression": "(((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')",
        "line": 0
    },
    {
        "name": "leaseBuyout.isTaxesRemittedBySeller",
        "title": "Taxes remitted by seller?",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')",
        "line": 0
    },
    {
        "name": "leaseBuyout.salesTaxIdNumber",
        "title": "Sales Tax ID #",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')) && (leaseBuyout && leaseBuyout.isTaxesRemittedBySeller)",
        "line": 0
    },
    {
        "name": "sameFranchise",
        "title": "Same Franchise?",
        "section": "Sale Info",
        "required": false,
        "type": "CheckBox",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((seller.isNotMyDealership != true) && ('4' == '4')) || ((seller.isNotMyDealership == true) && (seller.address.stateCode == 'CA'))",
        "tooltip": "Please check if the vehicle is being sold by a dealer of the same franchise",
        "line": 0
    },
    {
        "name": "seller",
        "title": "Seller",
        "section": "Sale Info",
        "required": false,
        "type": "Seller",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "sellerParams": {
            "showName": true,
            "showAddress": true,
            "billToIdVisibilityExpression": "true",
            "billToIdTooltipExpression": "'This field is used under specialized transaction conditions for custom billing procedures'",
            "billToIdDisabledExpression": "!true",
            "phoneVisibilityExpression": "true",
            "phoneRequiredExpression": "false"
        },
        "line": 0
    },
    {
        "name": "registrationActionTypeId",
        "title": "Registration Action",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "applicationTypeId != '1'",
        "lookup": [
            {
                "code": "0",
                "name": "New Registration"
            },
            {
                "code": "1",
                "name": "Plate Transfer"
            }
        ],
        "line": 0
    },
    {
        "name": "useTypeId",
        "title": "Vehicle Use",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "applicationTypeId == '0'",
        "lookup": [
            {
                "code": "0",
                "name": "Personal Use"
            },
            {
                "code": "1",
                "name": "Commercial Use"
            },
            {
                "code": "2",
                "name": "For Hire"
            },
            {
                "code": "3",
                "name": "Off Highway"
            }
        ],
        "line": 0
    },
    {
        "name": "plateCategoryId",
        "title": "Plate Category",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(applicationTypeId == '0') && (vehicleTypeId != '8' && vehicleTypeId != '7' && vehicleTypeId != '2')",
        "lookup": [
            {
                "code": "4",
                "name": "Disabled Plates"
            },
            {
                "code": "1",
                "name": "Personalized Plates"
            },
            {
                "code": "2",
                "name": "Specialty Plates"
            },
            {
                "code": "0",
                "name": "Standard Plates"
            }
        ],
        "lookupVisibilityExpression": "(($current == '2' || $current == '4' || $current == '1')) || (($current == '0') && (registrationActionTypeId != '1'))",
        "line": 0
    },
    {
        "name": "plateTypeId",
        "title": "Plate Type",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(plateCategoryId == '2' || plateCategoryId == '1')  && applicationTypeId == '0'",
        "lookup": [
            {
                "code": "0",
                "name": "Agricultural License Plate"
            },
            {
                "code": "1",
                "name": "Amateur Radio Plate"
            },
            {
                "code": "2",
                "name": "Arts Council License Plate"
            },
            {
                "code": "3",
                "name": "Breast Cancer Awareness License Plate"
            },
            {
                "code": "4",
                "name": "CA 1960’s Legacy Plate"
            },
            {
                "code": "5",
                "name": "CA Coastal Commission Plate"
            },
            {
                "code": "6",
                "name": "CA Firefighter Dependent Plate"
            },
            {
                "code": "7",
                "name": "CA Firefighter Plate"
            },
            {
                "code": "8",
                "name": "CA Memorial Plate"
            },
            {
                "code": "9",
                "name": "CA Museums Plate"
            },
            {
                "code": "10",
                "name": "Citizens Band Radio"
            },
            {
                "code": "11",
                "name": "Collegiate License Plate"
            },
            {
                "code": "12",
                "name": "Congressional Medal of Honor License Plate"
            },
            {
                "code": "13",
                "name": "Environmental License Plate"
            },
            {
                "code": "14",
                "name": "Gold Star License Plate"
            },
            {
                "code": "15",
                "name": "Honorary Consul License Plate"
            },
            {
                "code": "16",
                "name": "Honoring Veteran's Plate"
            },
            {
                "code": "17",
                "name": "Kids - Child Health and Safety Fund Plate "
            },
            {
                "code": "18",
                "name": "Lake Tahoe Conservancy Plate"
            },
            {
                "code": "19",
                "name": "Legion of Valor License Plate"
            },
            {
                "code": "20",
                "name": "Pearl Harbor Survivor License Plate"
            },
            {
                "code": "21",
                "name": "Pet Lover's Plate"
            },
            {
                "code": "22",
                "name": "Press License Plate"
            },
            {
                "code": "23",
                "name": "Prisoner of War Plate"
            },
            {
                "code": "24",
                "name": "Purple Heart Recipient License Plate "
            },
            {
                "code": "25",
                "name": "Veteran's Organization Plate "
            },
            {
                "code": "26",
                "name": "Yosemite Foundation Plate"
            }
        ],
        "lookupVisibilityExpression": "(plateCategoryId == '2' && $current != '10') || (plateCategoryId == '1' && ($current == '0' || $current == '2' || $current == '3' || $current == '4' || $current == '8' || $current == '9' || $current == '11' || $current == '5' || $current == '6' || $current == '7' || $current == '17' || $current == '18' || $current == '21' || $current == '25' || $current == '26')|| (plateCategoryId == '2' && $current == '10' && registrationActionTypeId == '1') )",
        "line": 0
    },
    {
        "name": "registrationExpirationDate",
        "title": "Registration Expiration Date",
        "section": "Title and Registration Info",
        "required": false,
        "type": "Date",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "dateParams": {
            "minDate": "RegistrationExpirationDateMinValue",
            "maxDate": "RegistrationExpirationDateMaxValue"
        },
        "visibilityExpression": "applicationTypeId != '1' && registrationActionTypeId == '1'\n                    && serviceType != 'FeeInquiry'\n                    && state != 'MN'\n                    && state != 'IL'",
        "line": 0
    },
    {
        "name": "titleStateId",
        "title": "Prior Title State",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "soldAsId == '1'",
        "lookup": [
            {
                "code": "0",
                "name": "Alaska"
            },
            {
                "code": "1",
                "name": "Alabama"
            },
            {
                "code": "2",
                "name": "Arkansas"
            },
            {
                "code": "3",
                "name": "Arizona"
            },
            {
                "code": "4",
                "name": "California"
            },
            {
                "code": "5",
                "name": "Colorado"
            },
            {
                "code": "6",
                "name": "Connecticut"
            },
            {
                "code": "7",
                "name": "District of Columbia"
            },
            {
                "code": "8",
                "name": "Delaware"
            },
            {
                "code": "9",
                "name": "Florida"
            },
            {
                "code": "10",
                "name": "Georgia"
            },
            {
                "code": "11",
                "name": "Hawaii"
            },
            {
                "code": "12",
                "name": "Iowa"
            },
            {
                "code": "13",
                "name": "Idaho"
            },
            {
                "code": "14",
                "name": "Illinois"
            },
            {
                "code": "15",
                "name": "Indiana"
            },
            {
                "code": "16",
                "name": "Kansas"
            },
            {
                "code": "17",
                "name": "Kentucky"
            },
            {
                "code": "18",
                "name": "Louisiana"
            },
            {
                "code": "19",
                "name": "Massachusetts"
            },
            {
                "code": "20",
                "name": "Maryland"
            },
            {
                "code": "21",
                "name": "Maine"
            },
            {
                "code": "22",
                "name": "Michigan"
            },
            {
                "code": "23",
                "name": "Minnesota"
            },
            {
                "code": "24",
                "name": "Missouri"
            },
            {
                "code": "25",
                "name": "Mississippi"
            },
            {
                "code": "26",
                "name": "Montana"
            },
            {
                "code": "27",
                "name": "North Carolina"
            },
            {
                "code": "28",
                "name": "North Dakota"
            },
            {
                "code": "29",
                "name": "Nebraska"
            },
            {
                "code": "30",
                "name": "New Hampshire"
            },
            {
                "code": "31",
                "name": "New Jersey"
            },
            {
                "code": "32",
                "name": "New Mexico"
            },
            {
                "code": "33",
                "name": "Nevada"
            },
            {
                "code": "34",
                "name": "New York"
            },
            {
                "code": "35",
                "name": "Ohio"
            },
            {
                "code": "36",
                "name": "Oklahoma"
            },
            {
                "code": "37",
                "name": "Oregon"
            },
            {
                "code": "38",
                "name": "Pennsylvania"
            },
            {
                "code": "39",
                "name": "Rhode Island"
            },
            {
                "code": "40",
                "name": "South Carolina"
            },
            {
                "code": "41",
                "name": "South Dakota"
            },
            {
                "code": "42",
                "name": "Tennessee"
            },
            {
                "code": "43",
                "name": "Texas"
            },
            {
                "code": "44",
                "name": "Utah"
            },
            {
                "code": "45",
                "name": "Virginia"
            },
            {
                "code": "46",
                "name": "Vermont"
            },
            {
                "code": "47",
                "name": "Washington"
            },
            {
                "code": "48",
                "name": "Wisconsin"
            },
            {
                "code": "49",
                "name": "West Virginia"
            },
            {
                "code": "50",
                "name": "Wyoming"
            }
        ],
        "line": 0
    },
    {
        "name": "noTitle",
        "title": "Title in hand?",
        "section": "Title and Registration Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "soldAsId == '1'",
        "tooltip": "Select no if prior titling/registration state does not issue title for selected vehicle type or title does not exist",
        "line": 0
    },
    {
        "name": "paperlessTitle",
        "title": "Title is electronic?",
        "section": "Title and Registration Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "soldAsId == '1'",
        "tooltip": "Select yes if electronic lien title",
        "line": 0
    },
    {
        "name": "isReturnedToState",
        "title": "Previously titled/registered in CA?",
        "section": "Title and Registration Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((applicationTypeId != '1') && (soldAsId == '1')) && (((seller.isNotMyDealership != true) && ('4' == '4')) || ((seller.isNotMyDealership == true) && (seller.address.stateCode == 'CA')))",
        "tooltip": "Indicate “yes” if the vehicle was previously titled in CA, subsequently registered in another state, and is now returning to CA.",
        "line": 0
    },
    {
        "name": "subPlate",
        "title": "Substitute Plate",
        "section": "Title and Registration Info",
        "required": false,
        "type": "CheckBox",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((seller.isNotMyDealership != true) && ('4' == '4')) || ((seller.isNotMyDealership == true) && (seller.address.stateCode == 'CA'))",
        "line": 0
    },
    {
        "name": "subSticker",
        "title": "Substitute Sticker",
        "section": "Title and Registration Info",
        "required": false,
        "type": "CheckBox",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((seller.isNotMyDealership != true) && ('4' == '4')) || ((seller.isNotMyDealership == true) && (seller.address.stateCode == 'CA'))",
        "line": 0
    },
    {
        "name": "registrationAddress",
        "title": "Registered Address",
        "section": "Title and Registration Info",
        "required": true,
        "type": "Address",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "addressParams": {
            "enableRemoteAddressVerification": true,
            "showAddressLine": true,
            "showAddressLine2": true,
            "disableStateCode": true,
            "disableCounty": false,
            "disableCity": false,
            "showCounty": true
        },
        "visibilityExpression": "applicationTypeId != '1'",
        "line": 0
    },
    {
        "name": "isPermanentResident",
        "title": "Is the Owner/Lessee a permanent/legal resident of CA?",
        "titleExpression": "saleTypeId == '0' ? 'Is the Owner a permanent/legal resident of CA?' : 'Is the Lessee a permanent/legal resident of CA?'",
        "section": "Owner Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "line": 0
    },
    {
        "name": "residenceAddress",
        "title": "Residence Address",
        "section": "Owner Info",
        "required": true,
        "type": "Address",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "addressParams": {
            "enableRemoteAddressVerification": true,
            "showAddressLine": true,
            "showAddressLine2": true,
            "disableStateCode": true,
            "disableCounty": true,
            "disableCity": true,
            "showCounty": true
        },
        "visibilityExpression": "applicationTypeId == '1'",
        "line": 0
    },
    {
        "name": "isMemberOfMilitary",
        "title": "Owner is military service member or service member's spouse?",
        "titleExpression": "saleTypeId == '0' ? 'Owner is service member or spouse of?' : 'Lessee is service member or spouse of?'",
        "section": "Military Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "true",
        "onChangeExpression": "if(record.owners && record.owners[0]) record.owners[0].ownerType = record.isMemberOfMilitary ? 'Individual' : record.owners[0].ownerType;if(record.owners && record.owners[1]) record.owners[1].ownerType =  record.isMemberOfMilitary ? 'Individual' : record.owners[1].ownerType;if(record.lease && record.lease.lessee1) record.lease.lessee1.ownerType = record.isMemberOfMilitary ? 'Individual' : record.lease.lessee1.ownerType;if(record.lease && record.lease.lessee2) record.lease.lessee2.ownerType = record.isMemberOfMilitary ? 'Individual' : record.lease.lessee2.ownerType;",
        "line": 0
    }
];
