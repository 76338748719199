"use client";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { CA_BASE_RECORD } from "./mocks/ca-base-record";
import { IL_BASE_RECORD } from "./mocks/il-base-record";
import { CA_DYNAMIC_FIELDS } from "./mocks/ca-dynamic-fields";
import { IL_DYNAMIC_FIELDS } from "./mocks/il-dynamic-fields";
// import { ReviewModal } from "./components/review-modal";
import ReviewModal  from "./ReviewModal";


import { CA_REVIEW_RESPONSE } from "./mocks/ca-review-response";

enum StateCode {
  CA = "CA",
  IL = "IL",
}

const baseRecordMap = {
  [StateCode.CA]: CA_BASE_RECORD,
  [StateCode.IL]: IL_BASE_RECORD,
};

const dynamicFieldsMap = {
  [StateCode.CA]: CA_DYNAMIC_FIELDS,
  [StateCode.IL]: IL_DYNAMIC_FIELDS,
};

const reviewResponseMap = {
  [StateCode.CA]: CA_REVIEW_RESPONSE,
  [StateCode.IL]: CA_REVIEW_RESPONSE,
}

type DynamicFormElement = HTMLElement & {
  dynamicFieldList: any[];
  record: any;
  generalFormSettings: any;

  highlightInvalidFields: () => void;
  currentFormData: any;
  currentValidationStatus: "VALID" | "INVALID";
}

export default function AutoForm() {

  const [selectedState, updateState] = useState<StateCode | undefined>(undefined);
  const [formValid, updateValidationStatus] = useState<boolean>(false);
  const [record, updateRecord] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const formContainerRef = useRef<HTMLFormElement | null>(null);
  const formRef = useRef<DynamicFormElement | null>(null);

  useEffect(() => {

      const ref = formContainerRef;

      const recordChangeHandler = (event: Event) => {
          updateRecord((event as CustomEvent<any>).detail);
      };

      const validationStatusChangeHandler = (event: Event) => {
          const isValid = (event as CustomEvent<any>).detail === "VALID";
          updateValidationStatus(isValid);
      };

      if (ref && selectedState) {

          const formElement = document.createElement("dynamic-form") as DynamicFormElement;
          formElement.dynamicFieldList = dynamicFieldsMap[selectedState];
          formElement.generalFormSettings = {};
          formElement.record = baseRecordMap[selectedState];
          updateRecord(baseRecordMap[selectedState]);

          formElement.addEventListener("recordChange", recordChangeHandler);
          formElement.addEventListener("validationStatusChange", validationStatusChangeHandler);

          ref.current!.appendChild(formElement);
          formRef.current = formElement;

          return () => {
              formRef.current = null;
              formElement.remove();
              formElement.removeEventListener("recordChange", recordChangeHandler);
              formElement.removeEventListener("validationStatusChange", validationStatusChangeHandler);
          }
      }
  }, [selectedState, formContainerRef]);

  const onStateChange = (event: ChangeEvent<HTMLSelectElement>) => {

      updateState(event.target.value as StateCode);
  }

  const onSubmit = () => {

      // if (formValid) alert("Submitted!");
      // else formRef.current?.highlightInvalidFields();

      setModalVisible(true);
  }

  const onModalSubmit = (formList: any) => {

      setModalVisible(false);

      alert(`Selected forms:\n${formList.map((i: any) => i.description).join("\n")}`);
  }

  const onModalCancel = () => {

      setModalVisible(false);
  }
  return (
      <main className="flex min-h-screen flex-col items-stretch justify-between p-[15px] white-bg">
          <div>
              <div className="form-group">
                  <label className="control-label required">State</label>
                  <select className="form-control"
                      onChange={onStateChange}>
                      <option value={undefined}>Not selected</option>
                      <option value={StateCode.CA}>CA</option>
                      <option value={StateCode.IL}>IL</option>
                  </select>
              </div>
          </div>
          <form className="h-full efileForm" ref={formContainerRef}></form>
          {formRef.current && <div>
              <div>
                  <button className="btn btn-primary mb-[15px]"
                      onClick={onSubmit}>
                      Submit
                  </button>
              </div>
              <pre>{ JSON.stringify(record, null, "  ") }</pre>
          </div>}
          {selectedState &&
              <ReviewModal
                  modalOpen={modalVisible}
                  record={record}
                  dynamicFieldList={dynamicFieldsMap[selectedState]}
                  applicationTypeName={"Title and Registration"}
                  reviewJsonResponse={reviewResponseMap[selectedState]}
                  onSubmit={onModalSubmit}
                  onCancel={onModalCancel}
              />
          }
          
      </main>
  );
}
