export const CA_REVIEW_RESPONSE = {
    "formList": [
        {
            "key": "FormsChecklist",
            "formKey": "FormsChecklist",
            "name": "FormsChecklist",
            "description": "Forms Checklist",
            "enabled": true,
            "required": true,
            "hasFile": false,
            "onlyVehicleInfo": false,
            "orderNo": 1,
            "state": "CA",
            "rsForm": false,
            "formNote": ""
        },
        {
            "key": "FeeReport",
            "formKey": "FeeReport",
            "name": "CA",
            "description": "Fee Quote",
            "enabled": true,
            "required": true,
            "hasFile": false,
            "onlyVehicleInfo": false,
            "orderNo": 2,
            "state": "CA",
            "rsForm": false,
            "formNote": ""
        },
        {
            "key": "",
            "formKey": "Title",
            "name": "Title",
            "description": "Original Certificate of Title ",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 4,
            "state": "CA",
            "rsForm": false,
            "formNote": "All applicable reassignments and secure POAs must be attached. Reassignments must be from the seller's state. If a lien is present it must be signed off on the title or a separate notarized lien release must be provided."
        },
        {
            "key": "",
            "formKey": "ID-P",
            "name": "ID-P",
            "description": "Photocopy of Valid Driver's License or State ID Card (Primary Owner)",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 6,
            "state": "CA",
            "rsForm": false,
            "formNote": "A valid driver's license, state ID card or a valid passport are acceptable forms of identification. Out-of-state identification is acceptable."
        },
        {
            "key": "",
            "formKey": "Reg343",
            "name": "Reg343",
            "description": "Reg 343: Application for Title or Registration (Original)",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 9,
            "state": "CA",
            "rsForm": false,
            "formNote": "Section 7 must indicate whether taxes were collected on behalf of your state. Must be signed by all owners. Can be signed by POA."
        },
        {
            "key": "",
            "formKey": "Reg262",
            "name": "Reg262",
            "description": "Reg 262: Vehicle/Vessel Transfer and Reassignment Form",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 10,
            "state": "CA",
            "rsForm": false,
            "formNote": "Cannot be signed by POA."
        },
        {
            "key": "",
            "formKey": "Reg260(Open)",
            "name": "Reg260(Open)",
            "description": "Reg 260: Original Power of Attorney (Open POA)",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 12,
            "state": "CA",
            "rsForm": false,
            "formNote": "Does not require notarization."
        },
        {
            "key": "",
            "formKey": "Reg256",
            "name": "Reg256",
            "description": "Reg 256: Statement of Facts & Buyer One and The Same (Original)",
            "enabled": true,
            "required": false,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 13,
            "state": "CA",
            "rsForm": false,
            "formNote": "Required if the owner name(s) differs on any documents from their photo ID or Drivers License. Vehicle owners living in unincorporated areas of certain cities may be exempt from additional use tax; this form is required to indicate their residence is located in such an area. Additionally, a screenshot from CDTFA will be required. The CDTFA-111 may also be used. For any Statement of Facts or Name Statements concerning the owner(s), signature by POA will not be accepted."
        },
        {
            "key": "",
            "formKey": "Reg31",
            "name": "Reg31",
            "description": "Reg 31: Verification of Vehicle (Original, Revised 10/2018 or 04/2023)",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 21,
            "state": "CA",
            "rsForm": false,
            "formNote": "Must be hand printed and signed in black or blue ink. If conducted by a Highway Patrol Officer/Peace Office, verification can be completed in any state. Otherwise, the form must be completed by a licensed verifier in CA. Form should NOT be signed by the customer unless there is a VIN error. For motorhomes, inspection must be completed based on the newest MCO."
        },
        {
            "key": "",
            "formKey": "DMV14",
            "name": "DMV14",
            "description": "DMV 14: Notice of Change of Address (Original)",
            "enabled": true,
            "required": false,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 27,
            "state": "CA",
            "rsForm": false,
            "formNote": "Required when the registered address is a P.O. Box or the registered address is different from the a mailing address."
        },
        {
            "key": "",
            "formKey": "SmogCert",
            "name": "SmogCert",
            "description": "California Smog Certification",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 33,
            "state": "CA",
            "rsForm": false,
            "formNote": "Must be completed at a CA-Certified smog station. Certification can be a clear copy. Inspection must list the VIN and display a passing result. For Lease buyout vehicles that are currently registered in CA, a smog might be due for if the vehicle has a model year over or equal to 8 years. If the vehicle is not registered in CA, a smog will be required."
        },
        {
            "key": "",
            "formKey": "DealerOneAndTheSame",
            "name": "DealerOneAndTheSame",
            "description": "Dealer One and The Same",
            "enabled": true,
            "required": false,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 42,
            "state": "CA",
            "rsForm": false,
            "formNote": "Use form if dealer goes by a variation of name(s). Dealer's name must match on submitted paperwork. Can be printed on dealer letterhead or on CA REG 256: Statement of Facts."
        },
        {
            "key": "",
            "formKey": "Reg101",
            "name": "Reg101",
            "description": "Reg 101: Statement to Record Ownership and/or Statement of Error Or Erasure",
            "enabled": true,
            "required": false,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 43,
            "state": "CA",
            "rsForm": false,
            "formNote": "Completed when an error or erasure is made on the California title. For errors made on the assignment to the owner or lienholder, both top and bottom portions must be completed and signed by the individual who made the error/erasure."
        },
        {
            "key": "",
            "formKey": "CDTFA-146-RES",
            "name": "CDTFA-146-RES",
            "description": "CDTFA 146 RES: Exemption Certificate and Statement of Delivery in Indian Country",
            "enabled": true,
            "required": false,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 44,
            "state": "CA",
            "rsForm": false,
            "formNote": "Used to document delivery of vehicle to Indian Country by a CA dealer. Do not submit this form for title and registration purposes."
        },
        {
            "key": "",
            "formKey": "CASalesTaxWorksheet",
            "name": "CASalesTaxWorksheet",
            "description": "California Sales Tax Worksheet",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 51,
            "state": "CA",
            "rsForm": false,
            "formNote": "Must be complete and accompanied by any necessary supporting documentation."
        },
        {
            "key": "",
            "formKey": "LienDisclosure",
            "name": "LienDisclosure",
            "description": "Lien Disclosure Form",
            "enabled": true,
            "required": true,
            "hasFile": true,
            "onlyVehicleInfo": false,
            "orderNo": 53,
            "state": "CA",
            "rsForm": false,
            "formNote": "Required for all transactions submitted without a lienholder."
        }
    ],
    "feeNames": [
        {
            "newValue": 612.0,
            "difference": 612.0,
            "paymentType": "Regular",
            "name": "CA State Tax (6%)",
            "status": "New"
        },
        {
            "newValue": 102.0,
            "difference": 102.0,
            "paymentType": "Regular",
            "name": "CA County Tax (1%)",
            "status": "New"
        },
        {
            "newValue": 25.5,
            "difference": 25.5,
            "paymentType": "Regular",
            "name": "CA Bradley Burns Tax (0.25%)",
            "status": "New"
        },
        {
            "newValue": 229.5,
            "difference": 229.5,
            "paymentType": "Regular",
            "name": "CA District Tax (2.25%)",
            "status": "New"
        },
        {
            "newValue": 15.00,
            "difference": 15.00,
            "paymentType": "Regular",
            "name": "Transfer Fee",
            "status": "New"
        },
        {
            "newValue": 11.00,
            "difference": 11.00,
            "paymentType": "Regular",
            "name": "County District Fee",
            "status": "New"
        },
        {
            "newValue": 71.00,
            "difference": 71.00,
            "paymentType": "Regular",
            "name": "Registration Fee",
            "status": "New"
        },
        {
            "newValue": 32.00,
            "difference": 32.00,
            "paymentType": "Regular",
            "name": "California Highway Patrol Fee",
            "status": "New"
        },
        {
            "newValue": 1.00,
            "difference": 1.00,
            "paymentType": "Regular",
            "name": "Reflectorized Plate Fee",
            "status": "New"
        },
        {
            "newValue": 65.00,
            "difference": 65.00,
            "paymentType": "Regular",
            "name": "Transportation Improvement Fee",
            "status": "New"
        },
        {
            "newValue": 66.00,
            "difference": 66.00,
            "paymentType": "Regular",
            "name": "Vehicle License Fee",
            "status": "New"
        },
        {
            "newValue": 3.00,
            "difference": 3.00,
            "paymentType": "Regular",
            "name": "Alternative Fuel",
            "status": "New"
        }
    ],
    "validationAddressResult": {
        "address": {
            "stateCode": "CA",
            "countyCode": "06037",
            "countyName": "Los Angeles",
            "city": "AGOURA HILLS",
            "addressLine1": "29901 AGOURA RD",
            "zipCode": "91301",
            "zipExt": "2513"
        },
        "warningMessage": "The delivery address was found in the National Database and no further information was required.",
        "isError": false,
        "fieldName": "registrationAddress"
    },
    "isDifferent": false,
    "ok": true
}
