export const IL_BASE_RECORD = {
    "locationId": 2,
    "serviceType": "FeeInquiry",
    "state": "IL",
    "applicationTypeId": "0",
    "isMemberOfMilitary": false,
    "saleTypeId": "0",
    "leaseBuyout": {
        "isLeaseBuyout": false
    },
    "plateCategoryId": "0",
    "disabledPlatePlacard": false,
    "tradeInVehicle": {
        "tradeInVehicleSelected": false
    },
    "secondTradeInVehicle": {
        "tradeInVehicleSelected": false
    },
    "thirdTradeInVehicle": {
        "tradeInVehicleSelected": false
    },
    "seller": {
        "isIndividual": false,
        "isNotMyDealership": false
    }
}
