export const IL_DYNAMIC_FIELDS = [
    {
        "name": "isMemberOfMilitary",
        "title": "Is the Owner/Lessee a member of the military?",
        "section": "",
        "subSection": "Line1",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "true",
        "onChangeExpression": "if(record.owners && record.owners[0]) record.owners[0].ownerType = record.isMemberOfMilitary ? 'Individual' : record.owners[0].ownerType;if(record.owners && record.owners[1]) record.owners[1].ownerType =  record.isMemberOfMilitary ? 'Individual' : record.owners[1].ownerType;if(record.lease && record.lease.lessee1) record.lease.lessee1.ownerType = record.isMemberOfMilitary ? 'Individual' : record.lease.lessee1.ownerType;if(record.lease && record.lease.lessee2) record.lease.lessee2.ownerType = record.isMemberOfMilitary ? 'Individual' : record.lease.lessee2.ownerType;",
        "line": 0
    },
    {
        "name": "vin",
        "title": "VIN",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": true,
        "type": "VIN",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "vinParams": {
            "titleExpression": "vehicleTypeId == '9' ? 'HIN' : 'VIN'"
        },
        "line": 0
    },
    {
        "name": "vehicleTypeId",
        "title": "Vehicle Type",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "lookup": [
            {
                "code": "0",
                "name": "Passenger"
            },
            {
                "code": "1",
                "name": "Motorcycle"
            },
            {
                "code": "2",
                "name": "Truck"
            },
            {
                "code": "3",
                "name": "Trailer"
            },
            {
                "code": "4",
                "name": "Motorhome"
            },
            {
                "code": "5",
                "name": "ATV"
            },
            {
                "code": "6",
                "name": "Low Speed Vehicle"
            },
            {
                "code": "7",
                "name": "Snowmobile"
            },
            {
                "code": "8",
                "name": "Van"
            },
            {
                "code": "9",
                "name": "Vessel"
            }
        ],
        "line": 0
    },
    {
        "name": "expediteTitle",
        "title": "Expedite Title?",
        "section": "",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(applicationTypeId == '1') && (((seller.isNotMyDealership != true) && ('4' == '15')) || ((seller.isNotMyDealership == true) && (seller.address.stateCode == 'IN')))",
        "lookup": [
            {
                "code": "0",
                "name": "Driven Off Lot"
            },
            {
                "code": "1",
                "name": "Vehicle Shipped"
            }
        ],
        "tooltip": "Expedited Titles are an additional $30, changing Application Type to Title & Registration will ensure the fastest processing time.",
        "line": 0
    },
    {
        "name": "make",
        "title": "Make",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": false,
        "type": "TypeAhead",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "requiredExpression": "true",
        "typeAheadSource": "MakeByContainsJson",
        "line": 0
    },
    {
        "name": "model",
        "title": "Model",
        "section": "Vehicle Info",
        "subSection": "Line1",
        "required": false,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "requiredExpression": "true",
        "line": 0
    },
    {
        "name": "bodyTypeId",
        "title": "Body Type",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": false,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "true",
        "requiredExpression": "true",
        "lookup": [
            {
                "code": "1",
                "name": "AIR COMP (AI)"
            },
            {
                "code": "2",
                "name": "ALL TERRN (AT)"
            },
            {
                "code": "3",
                "name": "AMBULANCE (AM)"
            },
            {
                "code": "4",
                "name": "AMPHIBIAN (AV)"
            },
            {
                "code": "5",
                "name": "ARMORD TK (AR)"
            },
            {
                "code": "6",
                "name": "ATV (AL)"
            },
            {
                "code": "7",
                "name": "AUTO CARY (AC)"
            },
            {
                "code": "8",
                "name": "AUTOCYCLE (AU)"
            },
            {
                "code": "9",
                "name": "BACKHOE (BH)"
            },
            {
                "code": "10",
                "name": "BEVRG RCK (BR)"
            },
            {
                "code": "11",
                "name": "BOAT TRLR (BT)"
            },
            {
                "code": "12",
                "name": "BOTTM DMP (DB)"
            },
            {
                "code": "13",
                "name": "BRSH CHPR (BC)"
            },
            {
                "code": "14",
                "name": "BUGGY (BG)"
            },
            {
                "code": "15",
                "name": "BULLDOZER (BD)"
            },
            {
                "code": "16",
                "name": "BUS (BU)"
            },
            {
                "code": "17",
                "name": "BUS SCHL (SB)"
            },
            {
                "code": "18",
                "name": "CAB CHASS (CB)"
            },
            {
                "code": "19",
                "name": "CABL REEL (CL)"
            },
            {
                "code": "20",
                "name": "CABRIOLET (CA)"
            },
            {
                "code": "21",
                "name": "CAMPER (KT)"
            },
            {
                "code": "22",
                "name": "CAMPING (CT)"
            },
            {
                "code": "23",
                "name": "CANOPY EX (CE)"
            },
            {
                "code": "24",
                "name": "CARGO (A1)"
            },
            {
                "code": "25",
                "name": "CARRIAGE (HA)"
            },
            {
                "code": "26",
                "name": "CARRYALL (LL)"
            },
            {
                "code": "27",
                "name": "CNST TRAC (TC)"
            },
            {
                "code": "28",
                "name": "CNVTR GER (CG)"
            },
            {
                "code": "29",
                "name": "COACH (CH)"
            },
            {
                "code": "30",
                "name": "COMM CAB (CC)"
            },
            {
                "code": "31",
                "name": "COMP## (CO)"
            },
            {
                "code": "32",
                "name": "CONCR MXR (CM)"
            },
            {
                "code": "33",
                "name": "CONVERTBL (CV)"
            },
            {
                "code": "34",
                "name": "COUPE (CP)"
            },
            {
                "code": "35",
                "name": "CRANE (CR)"
            },
            {
                "code": "36",
                "name": "CREW PK (CW)"
            },
            {
                "code": "37",
                "name": "CREW PKUP (CY)"
            },
            {
                "code": "38",
                "name": "CRUISER (CZ)"
            },
            {
                "code": "39",
                "name": "CUTAWAY (YY)"
            },
            {
                "code": "40",
                "name": "DELIVERY (DE)"
            },
            {
                "code": "41",
                "name": "DEPOT HCK (DH)"
            },
            {
                "code": "42",
                "name": "DIRT (DM)"
            },
            {
                "code": "43",
                "name": "DLX DL TK (DX)"
            },
            {
                "code": "44",
                "name": "DOLLY (DY)"
            },
            {
                "code": "45",
                "name": "DROP FLAT (DF)"
            },
            {
                "code": "46",
                "name": "DSPLY VAN (VD)"
            },
            {
                "code": "47",
                "name": "DUMP TK (DP)"
            },
            {
                "code": "48",
                "name": "DUMP TRLR (HD)"
            },
            {
                "code": "49",
                "name": "DUNE BUGY (DN)"
            },
            {
                "code": "50",
                "name": "ELECTRIC (EL)"
            },
            {
                "code": "51",
                "name": "ENDURO (EN)"
            },
            {
                "code": "52",
                "name": "EXT SPTVN (ES)"
            },
            {
                "code": "53",
                "name": "EXT VAN (EV)"
            },
            {
                "code": "54",
                "name": "EXT VAN (EC)"
            },
            {
                "code": "55",
                "name": "EXT WINVN (EW)"
            },
            {
                "code": "56",
                "name": "FARM EQIP (MF)"
            },
            {
                "code": "57",
                "name": "FARM TRAC (TF)"
            },
            {
                "code": "58",
                "name": "FASTBACK (FK)"
            },
            {
                "code": "59",
                "name": "FERTILIZR (FS)"
            },
            {
                "code": "60",
                "name": "FIRE APP (FT)"
            },
            {
                "code": "61",
                "name": "FIRE APP (FA)"
            },
            {
                "code": "62",
                "name": "FLATBED (FB)"
            },
            {
                "code": "63",
                "name": "FLATRACK (FR)"
            },
            {
                "code": "64",
                "name": "FLIP AXLE (FX)"
            },
            {
                "code": "65",
                "name": "FORKLIFT (FL)"
            },
            {
                "code": "66",
                "name": "FWRD CONT (FC)"
            },
            {
                "code": "67",
                "name": "GARBGE TK (GG)"
            },
            {
                "code": "68",
                "name": "GENERATOR (GE)"
            },
            {
                "code": "69",
                "name": "GLASS RAK (GR)"
            },
            {
                "code": "70",
                "name": "GLIDER (GL)"
            },
            {
                "code": "71",
                "name": "GO KART (GK)"
            },
            {
                "code": "72",
                "name": "GOLF CART (GC)"
            },
            {
                "code": "73",
                "name": "GONDOLA (GA)"
            },
            {
                "code": "74",
                "name": "GRADER (GD)"
            },
            {
                "code": "75",
                "name": "GRAIN/BOX (GN)"
            },
            {
                "code": "76",
                "name": "HARDTOP (HT)"
            },
            {
                "code": "77",
                "name": "HARDTP CV (RH)"
            },
            {
                "code": "78",
                "name": "HATCHBACK (HB)"
            },
            {
                "code": "79",
                "name": "HEARSE (HR)"
            },
            {
                "code": "80",
                "name": "HOPPER (HO)"
            },
            {
                "code": "81",
                "name": "HORSE TLR (HE)"
            },
            {
                "code": "82",
                "name": "INC CHASS (IC)"
            },
            {
                "code": "83",
                "name": "INC EXTVN (IE)"
            },
            {
                "code": "84",
                "name": "JEEPSTER (JP)"
            },
            {
                "code": "85",
                "name": "LIMOUSINE (LM)"
            },
            {
                "code": "86",
                "name": "LIVESTOCK (LS)"
            },
            {
                "code": "87",
                "name": "LIVESTOCK (LI)"
            },
            {
                "code": "88",
                "name": "LOADER (LD)"
            },
            {
                "code": "89",
                "name": "LOGGER (LG)"
            },
            {
                "code": "90",
                "name": "LOGSKIDDR (LK)"
            },
            {
                "code": "91",
                "name": "LOW SPEED (LV)"
            },
            {
                "code": "92",
                "name": "LOWBOY (LB)"
            },
            {
                "code": "93",
                "name": "LUNCH WGN (LW)"
            },
            {
                "code": "94",
                "name": "MAXI VAN (MV)"
            },
            {
                "code": "95",
                "name": "MINI BIKE (MK)"
            },
            {
                "code": "96",
                "name": "MINI BK (MN)"
            },
            {
                "code": "97",
                "name": "MINI CYCL (MY)"
            },
            {
                "code": "98",
                "name": "MINI MOTO (MM)"
            },
            {
                "code": "99",
                "name": "MINI ROAD (MR)"
            },
            {
                "code": "100",
                "name": "MISC (XX)"
            },
            {
                "code": "101",
                "name": "MOBILE HM (HS)"
            },
            {
                "code": "102",
                "name": "MOBLE OFF (MB)"
            },
            {
                "code": "103",
                "name": "MOPED (MP)"
            },
            {
                "code": "104",
                "name": "MOPED (MD)"
            },
            {
                "code": "105",
                "name": "MOTO CROS (MX)"
            },
            {
                "code": "106",
                "name": "MOTOR HOM (MH)"
            },
            {
                "code": "107",
                "name": "MOTORCYCL (MC)"
            },
            {
                "code": "108",
                "name": "MOTORCYCL (RS)"
            },
            {
                "code": "109",
                "name": "MTR SCOOT (MS)"
            },
            {
                "code": "110",
                "name": "MTRCL 3WL (MW)"
            },
            {
                "code": "111",
                "name": "MTRCY TRL (MT)"
            },
            {
                "code": "112",
                "name": "NOTCHBACK (NB)"
            },
            {
                "code": "113",
                "name": "OFF-ROAD (DT)"
            },
            {
                "code": "114",
                "name": "ONE SEAT (S1)"
            },
            {
                "code": "115",
                "name": "OPEN BODY (OP)"
            },
            {
                "code": "116",
                "name": "OPERA CP (OC)"
            },
            {
                "code": "117",
                "name": "PALLET (PL)"
            },
            {
                "code": "118",
                "name": "PANEL (PN)"
            },
            {
                "code": "119",
                "name": "PARCL DLV (PD)"
            },
            {
                "code": "120",
                "name": "PARK MODL (PA)"
            },
            {
                "code": "121",
                "name": "PASS CAR (PC)"
            },
            {
                "code": "122",
                "name": "PASS VAN (PV)"
            },
            {
                "code": "123",
                "name": "PHAETON (PH)"
            },
            {
                "code": "124",
                "name": "PICKUP (PK)"
            },
            {
                "code": "125",
                "name": "PICKUP RV (PM)"
            },
            {
                "code": "126",
                "name": "PK CAMPER (PR)"
            },
            {
                "code": "127",
                "name": "POLE/PIPE (LP)"
            },
            {
                "code": "128",
                "name": "PUMPER (PP)"
            },
            {
                "code": "129",
                "name": "RACER CYC (RC)"
            },
            {
                "code": "130",
                "name": "REEFER (RF)"
            },
            {
                "code": "131",
                "name": "ROAD/TRAL (RT)"
            },
            {
                "code": "132",
                "name": "ROADSTER (RD)"
            },
            {
                "code": "133",
                "name": "ROLLER (RO)"
            },
            {
                "code": "134",
                "name": "RUNABOUT (RU)"
            },
            {
                "code": "135",
                "name": "SCRAPER (SC)"
            },
            {
                "code": "136",
                "name": "SEDAN (SD)"
            },
            {
                "code": "137",
                "name": "SEDAN DEL (SL)"
            },
            {
                "code": "138",
                "name": "SEDAN DEL (NM)"
            },
            {
                "code": "139",
                "name": "SEMI TLR (SE)"
            },
            {
                "code": "140",
                "name": "SHOVEL (SH)"
            },
            {
                "code": "141",
                "name": "SIDE CAR (SR)"
            },
            {
                "code": "142",
                "name": "SKDSTRLDR (SK)"
            },
            {
                "code": "143",
                "name": "SNGL WHEL (1W)"
            },
            {
                "code": "144",
                "name": "SNOW RMVL (SO)"
            },
            {
                "code": "145",
                "name": "SNOWBL TL (SM)"
            },
            {
                "code": "146",
                "name": "SP.EQUIP. (SQ)"
            },
            {
                "code": "147",
                "name": "SP-CONSTD (SX)"
            },
            {
                "code": "148",
                "name": "SPEEDSTER (SZ)"
            },
            {
                "code": "149",
                "name": "SPORT (SP)"
            },
            {
                "code": "150",
                "name": "SPORT VAN (SV)"
            },
            {
                "code": "151",
                "name": "SPORT VAN (VA)"
            },
            {
                "code": "152",
                "name": "SPORTTRAC (E1)"
            },
            {
                "code": "153",
                "name": "ST SWEEPR (SS)"
            },
            {
                "code": "154",
                "name": "STAKE/RAK (ST)"
            },
            {
                "code": "155",
                "name": "STEP VAN (SN)"
            },
            {
                "code": "156",
                "name": "STN WAGON (SW)"
            },
            {
                "code": "157",
                "name": "SUPCAB PK (PS)"
            },
            {
                "code": "158",
                "name": "SUPERCAB (CS)"
            },
            {
                "code": "159",
                "name": "TANDEM (TA)"
            },
            {
                "code": "160",
                "name": "TANKER (TN)"
            },
            {
                "code": "161",
                "name": "TENT TLR (TE)"
            },
            {
                "code": "162",
                "name": "TILT CAB (TB)"
            },
            {
                "code": "163",
                "name": "TILT TAND (TM)"
            },
            {
                "code": "164",
                "name": "TOURNG (TO)"
            },
            {
                "code": "165",
                "name": "TOW DOLLY (DO)"
            },
            {
                "code": "166",
                "name": "TOW DOLLY (TD)"
            },
            {
                "code": "167",
                "name": "TOW TRUCK (TT)"
            },
            {
                "code": "168",
                "name": "TOW TRUCK (TW)"
            },
            {
                "code": "169",
                "name": "TOW TRUCK (WK)"
            },
            {
                "code": "170",
                "name": "TRACTR TK (TU)"
            },
            {
                "code": "171",
                "name": "TRAILER (TL)"
            },
            {
                "code": "172",
                "name": "TRAVELALL (XT)"
            },
            {
                "code": "173",
                "name": "TRAVELALL (WW)"
            },
            {
                "code": "174",
                "name": "TRAVL TLR (TV)"
            },
            {
                "code": "175",
                "name": "TRENCHER (TH)"
            },
            {
                "code": "176",
                "name": "TRIALS (TR)"
            },
            {
                "code": "177",
                "name": "TRIKE (TI)"
            },
            {
                "code": "178",
                "name": "TRKCAMPER (TP)"
            },
            {
                "code": "179",
                "name": "TRL TRUCK (DS)"
            },
            {
                "code": "180",
                "name": "TRLR JCKY (TJ)"
            },
            {
                "code": "181",
                "name": "TROLLEY (TY)"
            },
            {
                "code": "182",
                "name": "TRUCK (TK)"
            },
            {
                "code": "183",
                "name": "TRUCKSTER (TS)"
            },
            {
                "code": "184",
                "name": "TUDOR (UD)"
            },
            {
                "code": "185",
                "name": "TWO SEAT (S2)"
            },
            {
                "code": "186",
                "name": "TWO WHEEL (2W)"
            },
            {
                "code": "187",
                "name": "UNFNSH TK (IP)"
            },
            {
                "code": "188",
                "name": "UNKNOWN# (UN)"
            },
            {
                "code": "189",
                "name": "UTILITY (WG)"
            },
            {
                "code": "190",
                "name": "UTILITY (AW)"
            },
            {
                "code": "191",
                "name": "UTILITY (CU)"
            },
            {
                "code": "192",
                "name": "UTILITY (UT)"
            },
            {
                "code": "193",
                "name": "UTV (UV)"
            },
            {
                "code": "194",
                "name": "VAN (VN)"
            },
            {
                "code": "195",
                "name": "VAN CAMPR (VC)"
            },
            {
                "code": "196",
                "name": "VANETTE (VT)"
            },
            {
                "code": "197",
                "name": "WAGON (WA)"
            },
            {
                "code": "198",
                "name": "WELDER (WE)"
            },
            {
                "code": "199",
                "name": "WELL DRIL (WD)"
            },
            {
                "code": "200",
                "name": "WHL LOADR (WL)"
            },
            {
                "code": "201",
                "name": "WINCH (WI)"
            },
            {
                "code": "202",
                "name": "WINDOW VN (VW)"
            },
            {
                "code": "203",
                "name": "XUV (XV)"
            },
            {
                "code": "204",
                "name": "YARD TRAC (MO)"
            },
            {
                "code": "205",
                "name": "2 DOOR (2D)"
            },
            {
                "code": "206",
                "name": "2C (2C)"
            },
            {
                "code": "207",
                "name": "2D HACHBK (2H)"
            },
            {
                "code": "208",
                "name": "2D HARDTP (2P)"
            },
            {
                "code": "209",
                "name": "2D HARDTP (2T)"
            },
            {
                "code": "210",
                "name": "2D HARDTP (3D)"
            },
            {
                "code": "211",
                "name": "2D HARDTP (2F)"
            },
            {
                "code": "212",
                "name": "2D LIFTBK (2L)"
            },
            {
                "code": "213",
                "name": "2D SEDAN (2S)"
            },
            {
                "code": "214",
                "name": "3 DOOR (3P)"
            },
            {
                "code": "215",
                "name": "3D LIFTBK (3L)"
            },
            {
                "code": "216",
                "name": "3DR EXTCC (3C)"
            },
            {
                "code": "217",
                "name": "3DR EXTCP (3B)"
            },
            {
                "code": "218",
                "name": "4 DOOR (4D)"
            },
            {
                "code": "219",
                "name": "4D HARDTP (4T)"
            },
            {
                "code": "220",
                "name": "4D HARDTP (4P)"
            },
            {
                "code": "221",
                "name": "4D HTCHBK (4H)"
            },
            {
                "code": "222",
                "name": "4D LIFTBK (4L)"
            },
            {
                "code": "223",
                "name": "4D SEDAN (4S)"
            },
            {
                "code": "224",
                "name": "4DR (C4)"
            },
            {
                "code": "225",
                "name": "4DR EXTCC (4B)"
            },
            {
                "code": "226",
                "name": "4DR EXTCP (4C)"
            },
            {
                "code": "227",
                "name": "4WHEEL DR (4W)"
            },
            {
                "code": "228",
                "name": "5 DOOR (5D)"
            },
            {
                "code": "229",
                "name": "5D HTCHBK (5H)"
            },
            {
                "code": "230",
                "name": "8PAS SPVN (8V)"
            }
        ],
        "line": 0
    },
    {
        "name": "year",
        "title": "Year",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Year",
        "minLength": 0,
        "maxValue": 2100,
        "minValue": 1900,
        "formControlSize": 1,
        "line": 0
    },
    {
        "name": "gvw",
        "title": "GVW",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "maxValue": 100000,
        "minValue": 100,
        "formControlSize": 1,
        "visibilityExpression": "(!(applicationTypeId == '1' && serviceType == 'FeeInquiry')) && (vehicleTypeId != '9')",
        "requiredExpression": "true",
        "line": 0
    },
    {
        "name": "grossVehicleWeightRating",
        "title": "GVWR",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "maxValue": 100000,
        "minValue": 100,
        "formControlSize": 1,
        "requiredExpression": "saleTypeId == '1'",
        "line": 0
    },
    {
        "name": "fuelTypeId",
        "title": "Fuel Type",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "vehicleTypeId != '3'",
        "requiredExpression": "(applicationTypeId != '1') && (vehicleTypeId != '9')",
        "lookup": [
            {
                "code": "0",
                "name": "Gas"
            },
            {
                "code": "1",
                "name": "Diesel"
            },
            {
                "code": "2",
                "name": "Hybrid"
            },
            {
                "code": "3",
                "name": "Electric"
            }
        ],
        "line": 0
    },
    {
        "name": "engineCC",
        "title": "Engine CC",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": false,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "vehicleTypeId  == '1'",
        "line": 0
    },
    {
        "name": "seatingCapacity",
        "title": "Seating Capacity",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "requiredExpression": "saleTypeId == '1'",
        "line": 0
    },
    {
        "name": "isTwoStageVehicle",
        "title": "Is this a 2nd stage vehicle?",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "vehicleTypeId == '4'",
        "line": 0
    },
    {
        "name": "firstStageVin",
        "title": "1st Stage VIN",
        "section": "Vehicle Info",
        "subSection": "Line2",
        "required": true,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(vehicleTypeId == '4') && (isTwoStageVehicle)",
        "line": 0
    },
    {
        "name": "deliverMethodId",
        "title": "Delivery Method",
        "section": "Sale Info",
        "required": true,
        "type": "Radio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(applicationTypeId != '1') && (((seller.isNotMyDealership != true) && ('4' == '4')) || ((seller.isNotMyDealership == true) && (seller.address.stateCode == 'CA')))",
        "lookup": [
            {
                "code": "0",
                "name": "Driven Off Lot"
            },
            {
                "code": "1",
                "name": "Vehicle Shipped"
            }
        ],
        "line": 0
    },
    {
        "name": "saleTypeId",
        "title": "Sale Type",
        "section": "Sale Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "lookup": [
            {
                "code": "0",
                "name": "Purchase"
            },
            {
                "code": "1",
                "name": "Lease"
            }
        ],
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "purchaseDate",
        "title": "Purchase Date",
        "section": "Sale Info",
        "required": true,
        "type": "Date",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "dateParams": {
            "minDate": "01/01/1900"
        },
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "soldAsId",
        "title": "Sold As",
        "section": "Sale Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "lookup": [
            {
                "code": "0",
                "name": "New"
            },
            {
                "code": "1",
                "name": "Used"
            }
        ],
        "line": 0
    },
    {
        "name": "sellingPrice",
        "title": "Selling Price",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 1.01,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '0' || deliverMethodId == '0'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "tooltip": "Do not add rebates and/or discounts.",
        "subtextTitleExpression": "'Cash Price or Base Price'",
        "line": 0
    },
    {
        "name": "leasePaymentAmount",
        "title": "Lease Payment Amount",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '1'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "term",
        "title": "Term",
        "section": "Sale Info",
        "required": true,
        "type": "Number",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '1'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "tooltip": "Please add one additional month to the term for lease payment.",
        "line": 0
    },
    {
        "name": "amountDueAtSigning",
        "title": "Amount Due at Signing",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '1'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "documentFees",
        "title": "Document Fees",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '0'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "addOns",
        "title": "Add Ons",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '0'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "tooltip": "Only physical accessories added to the vehicle before the vehicle sales tax apply. Warranties/Service Contracts/GAP are not taxable.",
        "line": 0
    },
    {
        "name": "dealerServiceFee",
        "title": "Dealer Service Fee",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '0'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "deliveryFee",
        "title": "Delivery Fee",
        "section": "Sale Info",
        "required": true,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "saleTypeId == '0'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "taxableSellingPrice",
        "title": "Taxable Selling Price",
        "section": "Sale Info",
        "disabledExpression": "true",
        "required": false,
        "type": "Price",
        "minLength": 0,
        "maxValue": 999999.99,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "true",
        "line": 0
    },
    {
        "name": "controlNumber",
        "title": "Control Number",
        "section": "Sale Info",
        "required": false,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "line": 0
    },
    {
        "name": "tradeInVehicle",
        "title": "Trade-In Vehicle",
        "section": "Sale Info",
        "required": true,
        "type": "TradeInVehicle",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "tradeInParams": {
            "bodyTypeDropdown": [
                {
                    "code": "1",
                    "name": "AIR COMP (AI)"
                },
                {
                    "code": "2",
                    "name": "ALL TERRN (AT)"
                },
                {
                    "code": "3",
                    "name": "AMBULANCE (AM)"
                },
                {
                    "code": "4",
                    "name": "AMPHIBIAN (AV)"
                },
                {
                    "code": "5",
                    "name": "ARMORD TK (AR)"
                },
                {
                    "code": "6",
                    "name": "ATV (AL)"
                },
                {
                    "code": "7",
                    "name": "AUTO CARY (AC)"
                },
                {
                    "code": "8",
                    "name": "AUTOCYCLE (AU)"
                },
                {
                    "code": "9",
                    "name": "BACKHOE (BH)"
                },
                {
                    "code": "10",
                    "name": "BEVRG RCK (BR)"
                },
                {
                    "code": "11",
                    "name": "BOAT TRLR (BT)"
                },
                {
                    "code": "12",
                    "name": "BOTTM DMP (DB)"
                },
                {
                    "code": "13",
                    "name": "BRSH CHPR (BC)"
                },
                {
                    "code": "14",
                    "name": "BUGGY (BG)"
                },
                {
                    "code": "15",
                    "name": "BULLDOZER (BD)"
                },
                {
                    "code": "16",
                    "name": "BUS (BU)"
                },
                {
                    "code": "17",
                    "name": "BUS SCHL (SB)"
                },
                {
                    "code": "18",
                    "name": "CAB CHASS (CB)"
                },
                {
                    "code": "19",
                    "name": "CABL REEL (CL)"
                },
                {
                    "code": "20",
                    "name": "CABRIOLET (CA)"
                },
                {
                    "code": "21",
                    "name": "CAMPER (KT)"
                },
                {
                    "code": "22",
                    "name": "CAMPING (CT)"
                },
                {
                    "code": "23",
                    "name": "CANOPY EX (CE)"
                },
                {
                    "code": "24",
                    "name": "CARGO (A1)"
                },
                {
                    "code": "25",
                    "name": "CARRIAGE (HA)"
                },
                {
                    "code": "26",
                    "name": "CARRYALL (LL)"
                },
                {
                    "code": "27",
                    "name": "CNST TRAC (TC)"
                },
                {
                    "code": "28",
                    "name": "CNVTR GER (CG)"
                },
                {
                    "code": "29",
                    "name": "COACH (CH)"
                },
                {
                    "code": "30",
                    "name": "COMM CAB (CC)"
                },
                {
                    "code": "31",
                    "name": "COMP## (CO)"
                },
                {
                    "code": "32",
                    "name": "CONCR MXR (CM)"
                },
                {
                    "code": "33",
                    "name": "CONVERTBL (CV)"
                },
                {
                    "code": "34",
                    "name": "COUPE (CP)"
                },
                {
                    "code": "35",
                    "name": "CRANE (CR)"
                },
                {
                    "code": "36",
                    "name": "CREW PK (CW)"
                },
                {
                    "code": "37",
                    "name": "CREW PKUP (CY)"
                },
                {
                    "code": "38",
                    "name": "CRUISER (CZ)"
                },
                {
                    "code": "39",
                    "name": "CUTAWAY (YY)"
                },
                {
                    "code": "40",
                    "name": "DELIVERY (DE)"
                },
                {
                    "code": "41",
                    "name": "DEPOT HCK (DH)"
                },
                {
                    "code": "42",
                    "name": "DIRT (DM)"
                },
                {
                    "code": "43",
                    "name": "DLX DL TK (DX)"
                },
                {
                    "code": "44",
                    "name": "DOLLY (DY)"
                },
                {
                    "code": "45",
                    "name": "DROP FLAT (DF)"
                },
                {
                    "code": "46",
                    "name": "DSPLY VAN (VD)"
                },
                {
                    "code": "47",
                    "name": "DUMP TK (DP)"
                },
                {
                    "code": "48",
                    "name": "DUMP TRLR (HD)"
                },
                {
                    "code": "49",
                    "name": "DUNE BUGY (DN)"
                },
                {
                    "code": "50",
                    "name": "ELECTRIC (EL)"
                },
                {
                    "code": "51",
                    "name": "ENDURO (EN)"
                },
                {
                    "code": "52",
                    "name": "EXT SPTVN (ES)"
                },
                {
                    "code": "53",
                    "name": "EXT VAN (EV)"
                },
                {
                    "code": "54",
                    "name": "EXT VAN (EC)"
                },
                {
                    "code": "55",
                    "name": "EXT WINVN (EW)"
                },
                {
                    "code": "56",
                    "name": "FARM EQIP (MF)"
                },
                {
                    "code": "57",
                    "name": "FARM TRAC (TF)"
                },
                {
                    "code": "58",
                    "name": "FASTBACK (FK)"
                },
                {
                    "code": "59",
                    "name": "FERTILIZR (FS)"
                },
                {
                    "code": "60",
                    "name": "FIRE APP (FT)"
                },
                {
                    "code": "61",
                    "name": "FIRE APP (FA)"
                },
                {
                    "code": "62",
                    "name": "FLATBED (FB)"
                },
                {
                    "code": "63",
                    "name": "FLATRACK (FR)"
                },
                {
                    "code": "64",
                    "name": "FLIP AXLE (FX)"
                },
                {
                    "code": "65",
                    "name": "FORKLIFT (FL)"
                },
                {
                    "code": "66",
                    "name": "FWRD CONT (FC)"
                },
                {
                    "code": "67",
                    "name": "GARBGE TK (GG)"
                },
                {
                    "code": "68",
                    "name": "GENERATOR (GE)"
                },
                {
                    "code": "69",
                    "name": "GLASS RAK (GR)"
                },
                {
                    "code": "70",
                    "name": "GLIDER (GL)"
                },
                {
                    "code": "71",
                    "name": "GO KART (GK)"
                },
                {
                    "code": "72",
                    "name": "GOLF CART (GC)"
                },
                {
                    "code": "73",
                    "name": "GONDOLA (GA)"
                },
                {
                    "code": "74",
                    "name": "GRADER (GD)"
                },
                {
                    "code": "75",
                    "name": "GRAIN/BOX (GN)"
                },
                {
                    "code": "76",
                    "name": "HARDTOP (HT)"
                },
                {
                    "code": "77",
                    "name": "HARDTP CV (RH)"
                },
                {
                    "code": "78",
                    "name": "HATCHBACK (HB)"
                },
                {
                    "code": "79",
                    "name": "HEARSE (HR)"
                },
                {
                    "code": "80",
                    "name": "HOPPER (HO)"
                },
                {
                    "code": "81",
                    "name": "HORSE TLR (HE)"
                },
                {
                    "code": "82",
                    "name": "INC CHASS (IC)"
                },
                {
                    "code": "83",
                    "name": "INC EXTVN (IE)"
                },
                {
                    "code": "84",
                    "name": "JEEPSTER (JP)"
                },
                {
                    "code": "85",
                    "name": "LIMOUSINE (LM)"
                },
                {
                    "code": "86",
                    "name": "LIVESTOCK (LS)"
                },
                {
                    "code": "87",
                    "name": "LIVESTOCK (LI)"
                },
                {
                    "code": "88",
                    "name": "LOADER (LD)"
                },
                {
                    "code": "89",
                    "name": "LOGGER (LG)"
                },
                {
                    "code": "90",
                    "name": "LOGSKIDDR (LK)"
                },
                {
                    "code": "91",
                    "name": "LOW SPEED (LV)"
                },
                {
                    "code": "92",
                    "name": "LOWBOY (LB)"
                },
                {
                    "code": "93",
                    "name": "LUNCH WGN (LW)"
                },
                {
                    "code": "94",
                    "name": "MAXI VAN (MV)"
                },
                {
                    "code": "95",
                    "name": "MINI BIKE (MK)"
                },
                {
                    "code": "96",
                    "name": "MINI BK (MN)"
                },
                {
                    "code": "97",
                    "name": "MINI CYCL (MY)"
                },
                {
                    "code": "98",
                    "name": "MINI MOTO (MM)"
                },
                {
                    "code": "99",
                    "name": "MINI ROAD (MR)"
                },
                {
                    "code": "100",
                    "name": "MISC (XX)"
                },
                {
                    "code": "101",
                    "name": "MOBILE HM (HS)"
                },
                {
                    "code": "102",
                    "name": "MOBLE OFF (MB)"
                },
                {
                    "code": "103",
                    "name": "MOPED (MP)"
                },
                {
                    "code": "104",
                    "name": "MOPED (MD)"
                },
                {
                    "code": "105",
                    "name": "MOTO CROS (MX)"
                },
                {
                    "code": "106",
                    "name": "MOTOR HOM (MH)"
                },
                {
                    "code": "107",
                    "name": "MOTORCYCL (MC)"
                },
                {
                    "code": "108",
                    "name": "MOTORCYCL (RS)"
                },
                {
                    "code": "109",
                    "name": "MTR SCOOT (MS)"
                },
                {
                    "code": "110",
                    "name": "MTRCL 3WL (MW)"
                },
                {
                    "code": "111",
                    "name": "MTRCY TRL (MT)"
                },
                {
                    "code": "112",
                    "name": "NOTCHBACK (NB)"
                },
                {
                    "code": "113",
                    "name": "OFF-ROAD (DT)"
                },
                {
                    "code": "114",
                    "name": "ONE SEAT (S1)"
                },
                {
                    "code": "115",
                    "name": "OPEN BODY (OP)"
                },
                {
                    "code": "116",
                    "name": "OPERA CP (OC)"
                },
                {
                    "code": "117",
                    "name": "PALLET (PL)"
                },
                {
                    "code": "118",
                    "name": "PANEL (PN)"
                },
                {
                    "code": "119",
                    "name": "PARCL DLV (PD)"
                },
                {
                    "code": "120",
                    "name": "PARK MODL (PA)"
                },
                {
                    "code": "121",
                    "name": "PASS CAR (PC)"
                },
                {
                    "code": "122",
                    "name": "PASS VAN (PV)"
                },
                {
                    "code": "123",
                    "name": "PHAETON (PH)"
                },
                {
                    "code": "124",
                    "name": "PICKUP (PK)"
                },
                {
                    "code": "125",
                    "name": "PICKUP RV (PM)"
                },
                {
                    "code": "126",
                    "name": "PK CAMPER (PR)"
                },
                {
                    "code": "127",
                    "name": "POLE/PIPE (LP)"
                },
                {
                    "code": "128",
                    "name": "PUMPER (PP)"
                },
                {
                    "code": "129",
                    "name": "RACER CYC (RC)"
                },
                {
                    "code": "130",
                    "name": "REEFER (RF)"
                },
                {
                    "code": "131",
                    "name": "ROAD/TRAL (RT)"
                },
                {
                    "code": "132",
                    "name": "ROADSTER (RD)"
                },
                {
                    "code": "133",
                    "name": "ROLLER (RO)"
                },
                {
                    "code": "134",
                    "name": "RUNABOUT (RU)"
                },
                {
                    "code": "135",
                    "name": "SCRAPER (SC)"
                },
                {
                    "code": "136",
                    "name": "SEDAN (SD)"
                },
                {
                    "code": "137",
                    "name": "SEDAN DEL (SL)"
                },
                {
                    "code": "138",
                    "name": "SEDAN DEL (NM)"
                },
                {
                    "code": "139",
                    "name": "SEMI TLR (SE)"
                },
                {
                    "code": "140",
                    "name": "SHOVEL (SH)"
                },
                {
                    "code": "141",
                    "name": "SIDE CAR (SR)"
                },
                {
                    "code": "142",
                    "name": "SKDSTRLDR (SK)"
                },
                {
                    "code": "143",
                    "name": "SNGL WHEL (1W)"
                },
                {
                    "code": "144",
                    "name": "SNOW RMVL (SO)"
                },
                {
                    "code": "145",
                    "name": "SNOWBL TL (SM)"
                },
                {
                    "code": "146",
                    "name": "SP.EQUIP. (SQ)"
                },
                {
                    "code": "147",
                    "name": "SP-CONSTD (SX)"
                },
                {
                    "code": "148",
                    "name": "SPEEDSTER (SZ)"
                },
                {
                    "code": "149",
                    "name": "SPORT (SP)"
                },
                {
                    "code": "150",
                    "name": "SPORT VAN (SV)"
                },
                {
                    "code": "151",
                    "name": "SPORT VAN (VA)"
                },
                {
                    "code": "152",
                    "name": "SPORTTRAC (E1)"
                },
                {
                    "code": "153",
                    "name": "ST SWEEPR (SS)"
                },
                {
                    "code": "154",
                    "name": "STAKE/RAK (ST)"
                },
                {
                    "code": "155",
                    "name": "STEP VAN (SN)"
                },
                {
                    "code": "156",
                    "name": "STN WAGON (SW)"
                },
                {
                    "code": "157",
                    "name": "SUPCAB PK (PS)"
                },
                {
                    "code": "158",
                    "name": "SUPERCAB (CS)"
                },
                {
                    "code": "159",
                    "name": "TANDEM (TA)"
                },
                {
                    "code": "160",
                    "name": "TANKER (TN)"
                },
                {
                    "code": "161",
                    "name": "TENT TLR (TE)"
                },
                {
                    "code": "162",
                    "name": "TILT CAB (TB)"
                },
                {
                    "code": "163",
                    "name": "TILT TAND (TM)"
                },
                {
                    "code": "164",
                    "name": "TOURNG (TO)"
                },
                {
                    "code": "165",
                    "name": "TOW DOLLY (DO)"
                },
                {
                    "code": "166",
                    "name": "TOW DOLLY (TD)"
                },
                {
                    "code": "167",
                    "name": "TOW TRUCK (TT)"
                },
                {
                    "code": "168",
                    "name": "TOW TRUCK (TW)"
                },
                {
                    "code": "169",
                    "name": "TOW TRUCK (WK)"
                },
                {
                    "code": "170",
                    "name": "TRACTR TK (TU)"
                },
                {
                    "code": "171",
                    "name": "TRAILER (TL)"
                },
                {
                    "code": "172",
                    "name": "TRAVELALL (XT)"
                },
                {
                    "code": "173",
                    "name": "TRAVELALL (WW)"
                },
                {
                    "code": "174",
                    "name": "TRAVL TLR (TV)"
                },
                {
                    "code": "175",
                    "name": "TRENCHER (TH)"
                },
                {
                    "code": "176",
                    "name": "TRIALS (TR)"
                },
                {
                    "code": "177",
                    "name": "TRIKE (TI)"
                },
                {
                    "code": "178",
                    "name": "TRKCAMPER (TP)"
                },
                {
                    "code": "179",
                    "name": "TRL TRUCK (DS)"
                },
                {
                    "code": "180",
                    "name": "TRLR JCKY (TJ)"
                },
                {
                    "code": "181",
                    "name": "TROLLEY (TY)"
                },
                {
                    "code": "182",
                    "name": "TRUCK (TK)"
                },
                {
                    "code": "183",
                    "name": "TRUCKSTER (TS)"
                },
                {
                    "code": "184",
                    "name": "TUDOR (UD)"
                },
                {
                    "code": "185",
                    "name": "TWO SEAT (S2)"
                },
                {
                    "code": "186",
                    "name": "TWO WHEEL (2W)"
                },
                {
                    "code": "187",
                    "name": "UNFNSH TK (IP)"
                },
                {
                    "code": "188",
                    "name": "UNKNOWN# (UN)"
                },
                {
                    "code": "189",
                    "name": "UTILITY (WG)"
                },
                {
                    "code": "190",
                    "name": "UTILITY (AW)"
                },
                {
                    "code": "191",
                    "name": "UTILITY (CU)"
                },
                {
                    "code": "192",
                    "name": "UTILITY (UT)"
                },
                {
                    "code": "193",
                    "name": "UTV (UV)"
                },
                {
                    "code": "194",
                    "name": "VAN (VN)"
                },
                {
                    "code": "195",
                    "name": "VAN CAMPR (VC)"
                },
                {
                    "code": "196",
                    "name": "VANETTE (VT)"
                },
                {
                    "code": "197",
                    "name": "WAGON (WA)"
                },
                {
                    "code": "198",
                    "name": "WELDER (WE)"
                },
                {
                    "code": "199",
                    "name": "WELL DRIL (WD)"
                },
                {
                    "code": "200",
                    "name": "WHL LOADR (WL)"
                },
                {
                    "code": "201",
                    "name": "WINCH (WI)"
                },
                {
                    "code": "202",
                    "name": "WINDOW VN (VW)"
                },
                {
                    "code": "203",
                    "name": "XUV (XV)"
                },
                {
                    "code": "204",
                    "name": "YARD TRAC (MO)"
                },
                {
                    "code": "205",
                    "name": "2 DOOR (2D)"
                },
                {
                    "code": "206",
                    "name": "2C (2C)"
                },
                {
                    "code": "207",
                    "name": "2D HACHBK (2H)"
                },
                {
                    "code": "208",
                    "name": "2D HARDTP (2P)"
                },
                {
                    "code": "209",
                    "name": "2D HARDTP (2T)"
                },
                {
                    "code": "210",
                    "name": "2D HARDTP (3D)"
                },
                {
                    "code": "211",
                    "name": "2D HARDTP (2F)"
                },
                {
                    "code": "212",
                    "name": "2D LIFTBK (2L)"
                },
                {
                    "code": "213",
                    "name": "2D SEDAN (2S)"
                },
                {
                    "code": "214",
                    "name": "3 DOOR (3P)"
                },
                {
                    "code": "215",
                    "name": "3D LIFTBK (3L)"
                },
                {
                    "code": "216",
                    "name": "3DR EXTCC (3C)"
                },
                {
                    "code": "217",
                    "name": "3DR EXTCP (3B)"
                },
                {
                    "code": "218",
                    "name": "4 DOOR (4D)"
                },
                {
                    "code": "219",
                    "name": "4D HARDTP (4T)"
                },
                {
                    "code": "220",
                    "name": "4D HARDTP (4P)"
                },
                {
                    "code": "221",
                    "name": "4D HTCHBK (4H)"
                },
                {
                    "code": "222",
                    "name": "4D LIFTBK (4L)"
                },
                {
                    "code": "223",
                    "name": "4D SEDAN (4S)"
                },
                {
                    "code": "224",
                    "name": "4DR (C4)"
                },
                {
                    "code": "225",
                    "name": "4DR EXTCC (4B)"
                },
                {
                    "code": "226",
                    "name": "4DR EXTCP (4C)"
                },
                {
                    "code": "227",
                    "name": "4WHEEL DR (4W)"
                },
                {
                    "code": "228",
                    "name": "5 DOOR (5D)"
                },
                {
                    "code": "229",
                    "name": "5D HTCHBK (5H)"
                },
                {
                    "code": "230",
                    "name": "8PAS SPVN (8V)"
                }
            ],
            "vehicleTypeDropdown": [
                {
                    "code": "0",
                    "name": "Passenger"
                },
                {
                    "code": "1",
                    "name": "Motorcycle"
                },
                {
                    "code": "2",
                    "name": "Truck"
                },
                {
                    "code": "3",
                    "name": "Trailer"
                },
                {
                    "code": "4",
                    "name": "Motorhome"
                },
                {
                    "code": "5",
                    "name": "ATV"
                },
                {
                    "code": "6",
                    "name": "Low Speed Vehicle"
                },
                {
                    "code": "7",
                    "name": "Snowmobile"
                },
                {
                    "code": "8",
                    "name": "Van"
                },
                {
                    "code": "9",
                    "name": "Vessel"
                }
            ],
            "vehicleClassificationDropdown": [
                {
                    "code": "0",
                    "name": "First Division"
                },
                {
                    "code": "1",
                    "name": "Second Division"
                },
                {
                    "code": "2",
                    "name": "Unknown"
                }
            ],
            "titlingStateDropdown": [
                {
                    "code": "0",
                    "name": "Alaska"
                },
                {
                    "code": "1",
                    "name": "Alabama"
                },
                {
                    "code": "2",
                    "name": "Arkansas"
                },
                {
                    "code": "3",
                    "name": "Arizona"
                },
                {
                    "code": "4",
                    "name": "California"
                },
                {
                    "code": "5",
                    "name": "Colorado"
                },
                {
                    "code": "6",
                    "name": "Connecticut"
                },
                {
                    "code": "7",
                    "name": "District of Columbia"
                },
                {
                    "code": "8",
                    "name": "Delaware"
                },
                {
                    "code": "9",
                    "name": "Florida"
                },
                {
                    "code": "10",
                    "name": "Georgia"
                },
                {
                    "code": "11",
                    "name": "Hawaii"
                },
                {
                    "code": "12",
                    "name": "Iowa"
                },
                {
                    "code": "13",
                    "name": "Idaho"
                },
                {
                    "code": "14",
                    "name": "Illinois"
                },
                {
                    "code": "15",
                    "name": "Indiana"
                },
                {
                    "code": "16",
                    "name": "Kansas"
                },
                {
                    "code": "17",
                    "name": "Kentucky"
                },
                {
                    "code": "18",
                    "name": "Louisiana"
                },
                {
                    "code": "19",
                    "name": "Massachusetts"
                },
                {
                    "code": "20",
                    "name": "Maryland"
                },
                {
                    "code": "21",
                    "name": "Maine"
                },
                {
                    "code": "22",
                    "name": "Michigan"
                },
                {
                    "code": "23",
                    "name": "Minnesota"
                },
                {
                    "code": "24",
                    "name": "Missouri"
                },
                {
                    "code": "25",
                    "name": "Mississippi"
                },
                {
                    "code": "26",
                    "name": "Montana"
                },
                {
                    "code": "27",
                    "name": "North Carolina"
                },
                {
                    "code": "28",
                    "name": "North Dakota"
                },
                {
                    "code": "29",
                    "name": "Nebraska"
                },
                {
                    "code": "30",
                    "name": "New Hampshire"
                },
                {
                    "code": "31",
                    "name": "New Jersey"
                },
                {
                    "code": "32",
                    "name": "New Mexico"
                },
                {
                    "code": "33",
                    "name": "Nevada"
                },
                {
                    "code": "34",
                    "name": "New York"
                },
                {
                    "code": "35",
                    "name": "Ohio"
                },
                {
                    "code": "36",
                    "name": "Oklahoma"
                },
                {
                    "code": "37",
                    "name": "Oregon"
                },
                {
                    "code": "38",
                    "name": "Pennsylvania"
                },
                {
                    "code": "39",
                    "name": "Rhode Island"
                },
                {
                    "code": "40",
                    "name": "South Carolina"
                },
                {
                    "code": "41",
                    "name": "South Dakota"
                },
                {
                    "code": "42",
                    "name": "Tennessee"
                },
                {
                    "code": "43",
                    "name": "Texas"
                },
                {
                    "code": "44",
                    "name": "Utah"
                },
                {
                    "code": "45",
                    "name": "Virginia"
                },
                {
                    "code": "46",
                    "name": "Vermont"
                },
                {
                    "code": "47",
                    "name": "Washington"
                },
                {
                    "code": "48",
                    "name": "Wisconsin"
                },
                {
                    "code": "49",
                    "name": "West Virginia"
                },
                {
                    "code": "50",
                    "name": "Wyoming"
                }
            ],
            "colorDropdown": [
                {
                    "code": "0",
                    "name": "Beige"
                },
                {
                    "code": "1",
                    "name": "Black"
                },
                {
                    "code": "2",
                    "name": "Blue"
                },
                {
                    "code": "3",
                    "name": "Blue, dark"
                },
                {
                    "code": "4",
                    "name": "Blue, light"
                },
                {
                    "code": "5",
                    "name": "Brown"
                },
                {
                    "code": "6",
                    "name": "Bronze"
                },
                {
                    "code": "7",
                    "name": "Chrome/Steel"
                },
                {
                    "code": "8",
                    "name": "Copper"
                },
                {
                    "code": "9",
                    "name": "Cream/Ivory"
                },
                {
                    "code": "10",
                    "name": "Gold"
                },
                {
                    "code": "11",
                    "name": "Gray"
                },
                {
                    "code": "12",
                    "name": "Green"
                },
                {
                    "code": "13",
                    "name": "Green, dark"
                },
                {
                    "code": "14",
                    "name": "Green, light"
                },
                {
                    "code": "15",
                    "name": "Ivory"
                },
                {
                    "code": "16",
                    "name": "Lavender"
                },
                {
                    "code": "17",
                    "name": "Maroon/Burgundy"
                },
                {
                    "code": "18",
                    "name": "Orange"
                },
                {
                    "code": "19",
                    "name": "Pink"
                },
                {
                    "code": "20",
                    "name": "Purple"
                },
                {
                    "code": "21",
                    "name": "Red"
                },
                {
                    "code": "22",
                    "name": "Silver/Aluminum"
                },
                {
                    "code": "23",
                    "name": "Tan"
                },
                {
                    "code": "24",
                    "name": "Turquoise"
                },
                {
                    "code": "25",
                    "name": "White"
                },
                {
                    "code": "26",
                    "name": "Yellow"
                }
            ],
            "makeVisibilityExpression": "true",
            "modelVisibilityExpression": "false",
            "yearVisibilityExpression": "true",
            "showExpirationYear": false,
            "titleCaption": "Title #",
            "showRegistrationNumber": false,
            "showLicenseNumber": false,
            "showStateRegistered": false,
            "tradeInValueVisibilityExpression": "true",
            "tradeInValueOnChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
            "tradeInValueRequiredExpression": "true",
            "makeTypeAheadSource": "MakeByContainsJson",
            "vinVisibilityExpression": "true",
            "vinRequiredExpression": "true",
            "makeRequiredExpression": "true",
            "yearRequiredExpression": "true",
            "vehicleTypeRequiredExpression": "true",
            "vehicleTypeVisibilityExpression": "true",
            "vehicleClassificationVisibilityExpression": "!purchaseDate || (purchaseDate.substr(0,4) < 2022)",
            "vehicleClassificationRequiredExpression": "true",
            "vehicleClassificationOnChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
            "vehicleClassificationTooltipDescription": "Second Division vehicles are defined as those designed to carry more than 10 persons, be used as living quarters and/or pull or carry property, freight or cargo regardless of actual vehicle use (i.e. pickups). SUVs are considered FIrst Division vehicles.",
            "stateRegisteredTitle": "State Registered"
        },
        "visibilityExpression": "saleTypeId == '0'",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "secondTradeInVehicle",
        "title": "Second Trade-In Vehicle",
        "section": "Sale Info",
        "required": true,
        "type": "TradeInVehicle",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "tradeInParams": {
            "bodyTypeDropdown": [
                {
                    "code": "1",
                    "name": "AIR COMP (AI)"
                },
                {
                    "code": "2",
                    "name": "ALL TERRN (AT)"
                },
                {
                    "code": "3",
                    "name": "AMBULANCE (AM)"
                },
                {
                    "code": "4",
                    "name": "AMPHIBIAN (AV)"
                },
                {
                    "code": "5",
                    "name": "ARMORD TK (AR)"
                },
                {
                    "code": "6",
                    "name": "ATV (AL)"
                },
                {
                    "code": "7",
                    "name": "AUTO CARY (AC)"
                },
                {
                    "code": "8",
                    "name": "AUTOCYCLE (AU)"
                },
                {
                    "code": "9",
                    "name": "BACKHOE (BH)"
                },
                {
                    "code": "10",
                    "name": "BEVRG RCK (BR)"
                },
                {
                    "code": "11",
                    "name": "BOAT TRLR (BT)"
                },
                {
                    "code": "12",
                    "name": "BOTTM DMP (DB)"
                },
                {
                    "code": "13",
                    "name": "BRSH CHPR (BC)"
                },
                {
                    "code": "14",
                    "name": "BUGGY (BG)"
                },
                {
                    "code": "15",
                    "name": "BULLDOZER (BD)"
                },
                {
                    "code": "16",
                    "name": "BUS (BU)"
                },
                {
                    "code": "17",
                    "name": "BUS SCHL (SB)"
                },
                {
                    "code": "18",
                    "name": "CAB CHASS (CB)"
                },
                {
                    "code": "19",
                    "name": "CABL REEL (CL)"
                },
                {
                    "code": "20",
                    "name": "CABRIOLET (CA)"
                },
                {
                    "code": "21",
                    "name": "CAMPER (KT)"
                },
                {
                    "code": "22",
                    "name": "CAMPING (CT)"
                },
                {
                    "code": "23",
                    "name": "CANOPY EX (CE)"
                },
                {
                    "code": "24",
                    "name": "CARGO (A1)"
                },
                {
                    "code": "25",
                    "name": "CARRIAGE (HA)"
                },
                {
                    "code": "26",
                    "name": "CARRYALL (LL)"
                },
                {
                    "code": "27",
                    "name": "CNST TRAC (TC)"
                },
                {
                    "code": "28",
                    "name": "CNVTR GER (CG)"
                },
                {
                    "code": "29",
                    "name": "COACH (CH)"
                },
                {
                    "code": "30",
                    "name": "COMM CAB (CC)"
                },
                {
                    "code": "31",
                    "name": "COMP## (CO)"
                },
                {
                    "code": "32",
                    "name": "CONCR MXR (CM)"
                },
                {
                    "code": "33",
                    "name": "CONVERTBL (CV)"
                },
                {
                    "code": "34",
                    "name": "COUPE (CP)"
                },
                {
                    "code": "35",
                    "name": "CRANE (CR)"
                },
                {
                    "code": "36",
                    "name": "CREW PK (CW)"
                },
                {
                    "code": "37",
                    "name": "CREW PKUP (CY)"
                },
                {
                    "code": "38",
                    "name": "CRUISER (CZ)"
                },
                {
                    "code": "39",
                    "name": "CUTAWAY (YY)"
                },
                {
                    "code": "40",
                    "name": "DELIVERY (DE)"
                },
                {
                    "code": "41",
                    "name": "DEPOT HCK (DH)"
                },
                {
                    "code": "42",
                    "name": "DIRT (DM)"
                },
                {
                    "code": "43",
                    "name": "DLX DL TK (DX)"
                },
                {
                    "code": "44",
                    "name": "DOLLY (DY)"
                },
                {
                    "code": "45",
                    "name": "DROP FLAT (DF)"
                },
                {
                    "code": "46",
                    "name": "DSPLY VAN (VD)"
                },
                {
                    "code": "47",
                    "name": "DUMP TK (DP)"
                },
                {
                    "code": "48",
                    "name": "DUMP TRLR (HD)"
                },
                {
                    "code": "49",
                    "name": "DUNE BUGY (DN)"
                },
                {
                    "code": "50",
                    "name": "ELECTRIC (EL)"
                },
                {
                    "code": "51",
                    "name": "ENDURO (EN)"
                },
                {
                    "code": "52",
                    "name": "EXT SPTVN (ES)"
                },
                {
                    "code": "53",
                    "name": "EXT VAN (EV)"
                },
                {
                    "code": "54",
                    "name": "EXT VAN (EC)"
                },
                {
                    "code": "55",
                    "name": "EXT WINVN (EW)"
                },
                {
                    "code": "56",
                    "name": "FARM EQIP (MF)"
                },
                {
                    "code": "57",
                    "name": "FARM TRAC (TF)"
                },
                {
                    "code": "58",
                    "name": "FASTBACK (FK)"
                },
                {
                    "code": "59",
                    "name": "FERTILIZR (FS)"
                },
                {
                    "code": "60",
                    "name": "FIRE APP (FT)"
                },
                {
                    "code": "61",
                    "name": "FIRE APP (FA)"
                },
                {
                    "code": "62",
                    "name": "FLATBED (FB)"
                },
                {
                    "code": "63",
                    "name": "FLATRACK (FR)"
                },
                {
                    "code": "64",
                    "name": "FLIP AXLE (FX)"
                },
                {
                    "code": "65",
                    "name": "FORKLIFT (FL)"
                },
                {
                    "code": "66",
                    "name": "FWRD CONT (FC)"
                },
                {
                    "code": "67",
                    "name": "GARBGE TK (GG)"
                },
                {
                    "code": "68",
                    "name": "GENERATOR (GE)"
                },
                {
                    "code": "69",
                    "name": "GLASS RAK (GR)"
                },
                {
                    "code": "70",
                    "name": "GLIDER (GL)"
                },
                {
                    "code": "71",
                    "name": "GO KART (GK)"
                },
                {
                    "code": "72",
                    "name": "GOLF CART (GC)"
                },
                {
                    "code": "73",
                    "name": "GONDOLA (GA)"
                },
                {
                    "code": "74",
                    "name": "GRADER (GD)"
                },
                {
                    "code": "75",
                    "name": "GRAIN/BOX (GN)"
                },
                {
                    "code": "76",
                    "name": "HARDTOP (HT)"
                },
                {
                    "code": "77",
                    "name": "HARDTP CV (RH)"
                },
                {
                    "code": "78",
                    "name": "HATCHBACK (HB)"
                },
                {
                    "code": "79",
                    "name": "HEARSE (HR)"
                },
                {
                    "code": "80",
                    "name": "HOPPER (HO)"
                },
                {
                    "code": "81",
                    "name": "HORSE TLR (HE)"
                },
                {
                    "code": "82",
                    "name": "INC CHASS (IC)"
                },
                {
                    "code": "83",
                    "name": "INC EXTVN (IE)"
                },
                {
                    "code": "84",
                    "name": "JEEPSTER (JP)"
                },
                {
                    "code": "85",
                    "name": "LIMOUSINE (LM)"
                },
                {
                    "code": "86",
                    "name": "LIVESTOCK (LS)"
                },
                {
                    "code": "87",
                    "name": "LIVESTOCK (LI)"
                },
                {
                    "code": "88",
                    "name": "LOADER (LD)"
                },
                {
                    "code": "89",
                    "name": "LOGGER (LG)"
                },
                {
                    "code": "90",
                    "name": "LOGSKIDDR (LK)"
                },
                {
                    "code": "91",
                    "name": "LOW SPEED (LV)"
                },
                {
                    "code": "92",
                    "name": "LOWBOY (LB)"
                },
                {
                    "code": "93",
                    "name": "LUNCH WGN (LW)"
                },
                {
                    "code": "94",
                    "name": "MAXI VAN (MV)"
                },
                {
                    "code": "95",
                    "name": "MINI BIKE (MK)"
                },
                {
                    "code": "96",
                    "name": "MINI BK (MN)"
                },
                {
                    "code": "97",
                    "name": "MINI CYCL (MY)"
                },
                {
                    "code": "98",
                    "name": "MINI MOTO (MM)"
                },
                {
                    "code": "99",
                    "name": "MINI ROAD (MR)"
                },
                {
                    "code": "100",
                    "name": "MISC (XX)"
                },
                {
                    "code": "101",
                    "name": "MOBILE HM (HS)"
                },
                {
                    "code": "102",
                    "name": "MOBLE OFF (MB)"
                },
                {
                    "code": "103",
                    "name": "MOPED (MP)"
                },
                {
                    "code": "104",
                    "name": "MOPED (MD)"
                },
                {
                    "code": "105",
                    "name": "MOTO CROS (MX)"
                },
                {
                    "code": "106",
                    "name": "MOTOR HOM (MH)"
                },
                {
                    "code": "107",
                    "name": "MOTORCYCL (MC)"
                },
                {
                    "code": "108",
                    "name": "MOTORCYCL (RS)"
                },
                {
                    "code": "109",
                    "name": "MTR SCOOT (MS)"
                },
                {
                    "code": "110",
                    "name": "MTRCL 3WL (MW)"
                },
                {
                    "code": "111",
                    "name": "MTRCY TRL (MT)"
                },
                {
                    "code": "112",
                    "name": "NOTCHBACK (NB)"
                },
                {
                    "code": "113",
                    "name": "OFF-ROAD (DT)"
                },
                {
                    "code": "114",
                    "name": "ONE SEAT (S1)"
                },
                {
                    "code": "115",
                    "name": "OPEN BODY (OP)"
                },
                {
                    "code": "116",
                    "name": "OPERA CP (OC)"
                },
                {
                    "code": "117",
                    "name": "PALLET (PL)"
                },
                {
                    "code": "118",
                    "name": "PANEL (PN)"
                },
                {
                    "code": "119",
                    "name": "PARCL DLV (PD)"
                },
                {
                    "code": "120",
                    "name": "PARK MODL (PA)"
                },
                {
                    "code": "121",
                    "name": "PASS CAR (PC)"
                },
                {
                    "code": "122",
                    "name": "PASS VAN (PV)"
                },
                {
                    "code": "123",
                    "name": "PHAETON (PH)"
                },
                {
                    "code": "124",
                    "name": "PICKUP (PK)"
                },
                {
                    "code": "125",
                    "name": "PICKUP RV (PM)"
                },
                {
                    "code": "126",
                    "name": "PK CAMPER (PR)"
                },
                {
                    "code": "127",
                    "name": "POLE/PIPE (LP)"
                },
                {
                    "code": "128",
                    "name": "PUMPER (PP)"
                },
                {
                    "code": "129",
                    "name": "RACER CYC (RC)"
                },
                {
                    "code": "130",
                    "name": "REEFER (RF)"
                },
                {
                    "code": "131",
                    "name": "ROAD/TRAL (RT)"
                },
                {
                    "code": "132",
                    "name": "ROADSTER (RD)"
                },
                {
                    "code": "133",
                    "name": "ROLLER (RO)"
                },
                {
                    "code": "134",
                    "name": "RUNABOUT (RU)"
                },
                {
                    "code": "135",
                    "name": "SCRAPER (SC)"
                },
                {
                    "code": "136",
                    "name": "SEDAN (SD)"
                },
                {
                    "code": "137",
                    "name": "SEDAN DEL (SL)"
                },
                {
                    "code": "138",
                    "name": "SEDAN DEL (NM)"
                },
                {
                    "code": "139",
                    "name": "SEMI TLR (SE)"
                },
                {
                    "code": "140",
                    "name": "SHOVEL (SH)"
                },
                {
                    "code": "141",
                    "name": "SIDE CAR (SR)"
                },
                {
                    "code": "142",
                    "name": "SKDSTRLDR (SK)"
                },
                {
                    "code": "143",
                    "name": "SNGL WHEL (1W)"
                },
                {
                    "code": "144",
                    "name": "SNOW RMVL (SO)"
                },
                {
                    "code": "145",
                    "name": "SNOWBL TL (SM)"
                },
                {
                    "code": "146",
                    "name": "SP.EQUIP. (SQ)"
                },
                {
                    "code": "147",
                    "name": "SP-CONSTD (SX)"
                },
                {
                    "code": "148",
                    "name": "SPEEDSTER (SZ)"
                },
                {
                    "code": "149",
                    "name": "SPORT (SP)"
                },
                {
                    "code": "150",
                    "name": "SPORT VAN (SV)"
                },
                {
                    "code": "151",
                    "name": "SPORT VAN (VA)"
                },
                {
                    "code": "152",
                    "name": "SPORTTRAC (E1)"
                },
                {
                    "code": "153",
                    "name": "ST SWEEPR (SS)"
                },
                {
                    "code": "154",
                    "name": "STAKE/RAK (ST)"
                },
                {
                    "code": "155",
                    "name": "STEP VAN (SN)"
                },
                {
                    "code": "156",
                    "name": "STN WAGON (SW)"
                },
                {
                    "code": "157",
                    "name": "SUPCAB PK (PS)"
                },
                {
                    "code": "158",
                    "name": "SUPERCAB (CS)"
                },
                {
                    "code": "159",
                    "name": "TANDEM (TA)"
                },
                {
                    "code": "160",
                    "name": "TANKER (TN)"
                },
                {
                    "code": "161",
                    "name": "TENT TLR (TE)"
                },
                {
                    "code": "162",
                    "name": "TILT CAB (TB)"
                },
                {
                    "code": "163",
                    "name": "TILT TAND (TM)"
                },
                {
                    "code": "164",
                    "name": "TOURNG (TO)"
                },
                {
                    "code": "165",
                    "name": "TOW DOLLY (DO)"
                },
                {
                    "code": "166",
                    "name": "TOW DOLLY (TD)"
                },
                {
                    "code": "167",
                    "name": "TOW TRUCK (TT)"
                },
                {
                    "code": "168",
                    "name": "TOW TRUCK (TW)"
                },
                {
                    "code": "169",
                    "name": "TOW TRUCK (WK)"
                },
                {
                    "code": "170",
                    "name": "TRACTR TK (TU)"
                },
                {
                    "code": "171",
                    "name": "TRAILER (TL)"
                },
                {
                    "code": "172",
                    "name": "TRAVELALL (XT)"
                },
                {
                    "code": "173",
                    "name": "TRAVELALL (WW)"
                },
                {
                    "code": "174",
                    "name": "TRAVL TLR (TV)"
                },
                {
                    "code": "175",
                    "name": "TRENCHER (TH)"
                },
                {
                    "code": "176",
                    "name": "TRIALS (TR)"
                },
                {
                    "code": "177",
                    "name": "TRIKE (TI)"
                },
                {
                    "code": "178",
                    "name": "TRKCAMPER (TP)"
                },
                {
                    "code": "179",
                    "name": "TRL TRUCK (DS)"
                },
                {
                    "code": "180",
                    "name": "TRLR JCKY (TJ)"
                },
                {
                    "code": "181",
                    "name": "TROLLEY (TY)"
                },
                {
                    "code": "182",
                    "name": "TRUCK (TK)"
                },
                {
                    "code": "183",
                    "name": "TRUCKSTER (TS)"
                },
                {
                    "code": "184",
                    "name": "TUDOR (UD)"
                },
                {
                    "code": "185",
                    "name": "TWO SEAT (S2)"
                },
                {
                    "code": "186",
                    "name": "TWO WHEEL (2W)"
                },
                {
                    "code": "187",
                    "name": "UNFNSH TK (IP)"
                },
                {
                    "code": "188",
                    "name": "UNKNOWN# (UN)"
                },
                {
                    "code": "189",
                    "name": "UTILITY (WG)"
                },
                {
                    "code": "190",
                    "name": "UTILITY (AW)"
                },
                {
                    "code": "191",
                    "name": "UTILITY (CU)"
                },
                {
                    "code": "192",
                    "name": "UTILITY (UT)"
                },
                {
                    "code": "193",
                    "name": "UTV (UV)"
                },
                {
                    "code": "194",
                    "name": "VAN (VN)"
                },
                {
                    "code": "195",
                    "name": "VAN CAMPR (VC)"
                },
                {
                    "code": "196",
                    "name": "VANETTE (VT)"
                },
                {
                    "code": "197",
                    "name": "WAGON (WA)"
                },
                {
                    "code": "198",
                    "name": "WELDER (WE)"
                },
                {
                    "code": "199",
                    "name": "WELL DRIL (WD)"
                },
                {
                    "code": "200",
                    "name": "WHL LOADR (WL)"
                },
                {
                    "code": "201",
                    "name": "WINCH (WI)"
                },
                {
                    "code": "202",
                    "name": "WINDOW VN (VW)"
                },
                {
                    "code": "203",
                    "name": "XUV (XV)"
                },
                {
                    "code": "204",
                    "name": "YARD TRAC (MO)"
                },
                {
                    "code": "205",
                    "name": "2 DOOR (2D)"
                },
                {
                    "code": "206",
                    "name": "2C (2C)"
                },
                {
                    "code": "207",
                    "name": "2D HACHBK (2H)"
                },
                {
                    "code": "208",
                    "name": "2D HARDTP (2P)"
                },
                {
                    "code": "209",
                    "name": "2D HARDTP (2T)"
                },
                {
                    "code": "210",
                    "name": "2D HARDTP (3D)"
                },
                {
                    "code": "211",
                    "name": "2D HARDTP (2F)"
                },
                {
                    "code": "212",
                    "name": "2D LIFTBK (2L)"
                },
                {
                    "code": "213",
                    "name": "2D SEDAN (2S)"
                },
                {
                    "code": "214",
                    "name": "3 DOOR (3P)"
                },
                {
                    "code": "215",
                    "name": "3D LIFTBK (3L)"
                },
                {
                    "code": "216",
                    "name": "3DR EXTCC (3C)"
                },
                {
                    "code": "217",
                    "name": "3DR EXTCP (3B)"
                },
                {
                    "code": "218",
                    "name": "4 DOOR (4D)"
                },
                {
                    "code": "219",
                    "name": "4D HARDTP (4T)"
                },
                {
                    "code": "220",
                    "name": "4D HARDTP (4P)"
                },
                {
                    "code": "221",
                    "name": "4D HTCHBK (4H)"
                },
                {
                    "code": "222",
                    "name": "4D LIFTBK (4L)"
                },
                {
                    "code": "223",
                    "name": "4D SEDAN (4S)"
                },
                {
                    "code": "224",
                    "name": "4DR (C4)"
                },
                {
                    "code": "225",
                    "name": "4DR EXTCC (4B)"
                },
                {
                    "code": "226",
                    "name": "4DR EXTCP (4C)"
                },
                {
                    "code": "227",
                    "name": "4WHEEL DR (4W)"
                },
                {
                    "code": "228",
                    "name": "5 DOOR (5D)"
                },
                {
                    "code": "229",
                    "name": "5D HTCHBK (5H)"
                },
                {
                    "code": "230",
                    "name": "8PAS SPVN (8V)"
                }
            ],
            "vehicleTypeDropdown": [
                {
                    "code": "0",
                    "name": "Passenger"
                },
                {
                    "code": "1",
                    "name": "Motorcycle"
                },
                {
                    "code": "2",
                    "name": "Truck"
                },
                {
                    "code": "3",
                    "name": "Trailer"
                },
                {
                    "code": "4",
                    "name": "Motorhome"
                },
                {
                    "code": "5",
                    "name": "ATV"
                },
                {
                    "code": "6",
                    "name": "Low Speed Vehicle"
                },
                {
                    "code": "7",
                    "name": "Snowmobile"
                },
                {
                    "code": "8",
                    "name": "Van"
                },
                {
                    "code": "9",
                    "name": "Vessel"
                }
            ],
            "vehicleClassificationDropdown": [
                {
                    "code": "0",
                    "name": "First Division"
                },
                {
                    "code": "1",
                    "name": "Second Division"
                },
                {
                    "code": "2",
                    "name": "Unknown"
                }
            ],
            "titlingStateDropdown": [
                {
                    "code": "0",
                    "name": "Alaska"
                },
                {
                    "code": "1",
                    "name": "Alabama"
                },
                {
                    "code": "2",
                    "name": "Arkansas"
                },
                {
                    "code": "3",
                    "name": "Arizona"
                },
                {
                    "code": "4",
                    "name": "California"
                },
                {
                    "code": "5",
                    "name": "Colorado"
                },
                {
                    "code": "6",
                    "name": "Connecticut"
                },
                {
                    "code": "7",
                    "name": "District of Columbia"
                },
                {
                    "code": "8",
                    "name": "Delaware"
                },
                {
                    "code": "9",
                    "name": "Florida"
                },
                {
                    "code": "10",
                    "name": "Georgia"
                },
                {
                    "code": "11",
                    "name": "Hawaii"
                },
                {
                    "code": "12",
                    "name": "Iowa"
                },
                {
                    "code": "13",
                    "name": "Idaho"
                },
                {
                    "code": "14",
                    "name": "Illinois"
                },
                {
                    "code": "15",
                    "name": "Indiana"
                },
                {
                    "code": "16",
                    "name": "Kansas"
                },
                {
                    "code": "17",
                    "name": "Kentucky"
                },
                {
                    "code": "18",
                    "name": "Louisiana"
                },
                {
                    "code": "19",
                    "name": "Massachusetts"
                },
                {
                    "code": "20",
                    "name": "Maryland"
                },
                {
                    "code": "21",
                    "name": "Maine"
                },
                {
                    "code": "22",
                    "name": "Michigan"
                },
                {
                    "code": "23",
                    "name": "Minnesota"
                },
                {
                    "code": "24",
                    "name": "Missouri"
                },
                {
                    "code": "25",
                    "name": "Mississippi"
                },
                {
                    "code": "26",
                    "name": "Montana"
                },
                {
                    "code": "27",
                    "name": "North Carolina"
                },
                {
                    "code": "28",
                    "name": "North Dakota"
                },
                {
                    "code": "29",
                    "name": "Nebraska"
                },
                {
                    "code": "30",
                    "name": "New Hampshire"
                },
                {
                    "code": "31",
                    "name": "New Jersey"
                },
                {
                    "code": "32",
                    "name": "New Mexico"
                },
                {
                    "code": "33",
                    "name": "Nevada"
                },
                {
                    "code": "34",
                    "name": "New York"
                },
                {
                    "code": "35",
                    "name": "Ohio"
                },
                {
                    "code": "36",
                    "name": "Oklahoma"
                },
                {
                    "code": "37",
                    "name": "Oregon"
                },
                {
                    "code": "38",
                    "name": "Pennsylvania"
                },
                {
                    "code": "39",
                    "name": "Rhode Island"
                },
                {
                    "code": "40",
                    "name": "South Carolina"
                },
                {
                    "code": "41",
                    "name": "South Dakota"
                },
                {
                    "code": "42",
                    "name": "Tennessee"
                },
                {
                    "code": "43",
                    "name": "Texas"
                },
                {
                    "code": "44",
                    "name": "Utah"
                },
                {
                    "code": "45",
                    "name": "Virginia"
                },
                {
                    "code": "46",
                    "name": "Vermont"
                },
                {
                    "code": "47",
                    "name": "Washington"
                },
                {
                    "code": "48",
                    "name": "Wisconsin"
                },
                {
                    "code": "49",
                    "name": "West Virginia"
                },
                {
                    "code": "50",
                    "name": "Wyoming"
                }
            ],
            "colorDropdown": [
                {
                    "code": "0",
                    "name": "Beige"
                },
                {
                    "code": "1",
                    "name": "Black"
                },
                {
                    "code": "2",
                    "name": "Blue"
                },
                {
                    "code": "3",
                    "name": "Blue, dark"
                },
                {
                    "code": "4",
                    "name": "Blue, light"
                },
                {
                    "code": "5",
                    "name": "Brown"
                },
                {
                    "code": "6",
                    "name": "Bronze"
                },
                {
                    "code": "7",
                    "name": "Chrome/Steel"
                },
                {
                    "code": "8",
                    "name": "Copper"
                },
                {
                    "code": "9",
                    "name": "Cream/Ivory"
                },
                {
                    "code": "10",
                    "name": "Gold"
                },
                {
                    "code": "11",
                    "name": "Gray"
                },
                {
                    "code": "12",
                    "name": "Green"
                },
                {
                    "code": "13",
                    "name": "Green, dark"
                },
                {
                    "code": "14",
                    "name": "Green, light"
                },
                {
                    "code": "15",
                    "name": "Ivory"
                },
                {
                    "code": "16",
                    "name": "Lavender"
                },
                {
                    "code": "17",
                    "name": "Maroon/Burgundy"
                },
                {
                    "code": "18",
                    "name": "Orange"
                },
                {
                    "code": "19",
                    "name": "Pink"
                },
                {
                    "code": "20",
                    "name": "Purple"
                },
                {
                    "code": "21",
                    "name": "Red"
                },
                {
                    "code": "22",
                    "name": "Silver/Aluminum"
                },
                {
                    "code": "23",
                    "name": "Tan"
                },
                {
                    "code": "24",
                    "name": "Turquoise"
                },
                {
                    "code": "25",
                    "name": "White"
                },
                {
                    "code": "26",
                    "name": "Yellow"
                }
            ],
            "makeVisibilityExpression": "true",
            "modelVisibilityExpression": "false",
            "yearVisibilityExpression": "true",
            "showExpirationYear": false,
            "titleCaption": "Title #",
            "showRegistrationNumber": false,
            "showLicenseNumber": false,
            "showStateRegistered": false,
            "tradeInValueVisibilityExpression": "true",
            "tradeInValueOnChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
            "tradeInValueRequiredExpression": "true",
            "makeTypeAheadSource": "MakeByContainsJson",
            "vinVisibilityExpression": "true",
            "vinRequiredExpression": "true",
            "makeRequiredExpression": "true",
            "yearRequiredExpression": "true",
            "vehicleTypeRequiredExpression": "true",
            "vehicleTypeVisibilityExpression": "true",
            "vehicleClassificationVisibilityExpression": "!purchaseDate || (purchaseDate.substr(0,4) < 2022)",
            "vehicleClassificationRequiredExpression": "true",
            "vehicleClassificationOnChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
            "vehicleClassificationTooltipDescription": "Second Division vehicles are defined as those designed to carry more than 10 persons, be used as living quarters and/or pull or carry property, freight or cargo regardless of actual vehicle use (i.e. pickups). SUVs are considered FIrst Division vehicles.",
            "stateRegisteredTitle": "State Registered"
        },
        "visibilityExpression": "(saleTypeId == '0') && (tradeInVehicle && tradeInVehicle.tradeInVehicleSelected)",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "thirdTradeInVehicle",
        "title": "Third Trade-In Vehicle",
        "section": "Sale Info",
        "required": true,
        "type": "TradeInVehicle",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "tradeInParams": {
            "bodyTypeDropdown": [
                {
                    "code": "1",
                    "name": "AIR COMP (AI)"
                },
                {
                    "code": "2",
                    "name": "ALL TERRN (AT)"
                },
                {
                    "code": "3",
                    "name": "AMBULANCE (AM)"
                },
                {
                    "code": "4",
                    "name": "AMPHIBIAN (AV)"
                },
                {
                    "code": "5",
                    "name": "ARMORD TK (AR)"
                },
                {
                    "code": "6",
                    "name": "ATV (AL)"
                },
                {
                    "code": "7",
                    "name": "AUTO CARY (AC)"
                },
                {
                    "code": "8",
                    "name": "AUTOCYCLE (AU)"
                },
                {
                    "code": "9",
                    "name": "BACKHOE (BH)"
                },
                {
                    "code": "10",
                    "name": "BEVRG RCK (BR)"
                },
                {
                    "code": "11",
                    "name": "BOAT TRLR (BT)"
                },
                {
                    "code": "12",
                    "name": "BOTTM DMP (DB)"
                },
                {
                    "code": "13",
                    "name": "BRSH CHPR (BC)"
                },
                {
                    "code": "14",
                    "name": "BUGGY (BG)"
                },
                {
                    "code": "15",
                    "name": "BULLDOZER (BD)"
                },
                {
                    "code": "16",
                    "name": "BUS (BU)"
                },
                {
                    "code": "17",
                    "name": "BUS SCHL (SB)"
                },
                {
                    "code": "18",
                    "name": "CAB CHASS (CB)"
                },
                {
                    "code": "19",
                    "name": "CABL REEL (CL)"
                },
                {
                    "code": "20",
                    "name": "CABRIOLET (CA)"
                },
                {
                    "code": "21",
                    "name": "CAMPER (KT)"
                },
                {
                    "code": "22",
                    "name": "CAMPING (CT)"
                },
                {
                    "code": "23",
                    "name": "CANOPY EX (CE)"
                },
                {
                    "code": "24",
                    "name": "CARGO (A1)"
                },
                {
                    "code": "25",
                    "name": "CARRIAGE (HA)"
                },
                {
                    "code": "26",
                    "name": "CARRYALL (LL)"
                },
                {
                    "code": "27",
                    "name": "CNST TRAC (TC)"
                },
                {
                    "code": "28",
                    "name": "CNVTR GER (CG)"
                },
                {
                    "code": "29",
                    "name": "COACH (CH)"
                },
                {
                    "code": "30",
                    "name": "COMM CAB (CC)"
                },
                {
                    "code": "31",
                    "name": "COMP## (CO)"
                },
                {
                    "code": "32",
                    "name": "CONCR MXR (CM)"
                },
                {
                    "code": "33",
                    "name": "CONVERTBL (CV)"
                },
                {
                    "code": "34",
                    "name": "COUPE (CP)"
                },
                {
                    "code": "35",
                    "name": "CRANE (CR)"
                },
                {
                    "code": "36",
                    "name": "CREW PK (CW)"
                },
                {
                    "code": "37",
                    "name": "CREW PKUP (CY)"
                },
                {
                    "code": "38",
                    "name": "CRUISER (CZ)"
                },
                {
                    "code": "39",
                    "name": "CUTAWAY (YY)"
                },
                {
                    "code": "40",
                    "name": "DELIVERY (DE)"
                },
                {
                    "code": "41",
                    "name": "DEPOT HCK (DH)"
                },
                {
                    "code": "42",
                    "name": "DIRT (DM)"
                },
                {
                    "code": "43",
                    "name": "DLX DL TK (DX)"
                },
                {
                    "code": "44",
                    "name": "DOLLY (DY)"
                },
                {
                    "code": "45",
                    "name": "DROP FLAT (DF)"
                },
                {
                    "code": "46",
                    "name": "DSPLY VAN (VD)"
                },
                {
                    "code": "47",
                    "name": "DUMP TK (DP)"
                },
                {
                    "code": "48",
                    "name": "DUMP TRLR (HD)"
                },
                {
                    "code": "49",
                    "name": "DUNE BUGY (DN)"
                },
                {
                    "code": "50",
                    "name": "ELECTRIC (EL)"
                },
                {
                    "code": "51",
                    "name": "ENDURO (EN)"
                },
                {
                    "code": "52",
                    "name": "EXT SPTVN (ES)"
                },
                {
                    "code": "53",
                    "name": "EXT VAN (EV)"
                },
                {
                    "code": "54",
                    "name": "EXT VAN (EC)"
                },
                {
                    "code": "55",
                    "name": "EXT WINVN (EW)"
                },
                {
                    "code": "56",
                    "name": "FARM EQIP (MF)"
                },
                {
                    "code": "57",
                    "name": "FARM TRAC (TF)"
                },
                {
                    "code": "58",
                    "name": "FASTBACK (FK)"
                },
                {
                    "code": "59",
                    "name": "FERTILIZR (FS)"
                },
                {
                    "code": "60",
                    "name": "FIRE APP (FT)"
                },
                {
                    "code": "61",
                    "name": "FIRE APP (FA)"
                },
                {
                    "code": "62",
                    "name": "FLATBED (FB)"
                },
                {
                    "code": "63",
                    "name": "FLATRACK (FR)"
                },
                {
                    "code": "64",
                    "name": "FLIP AXLE (FX)"
                },
                {
                    "code": "65",
                    "name": "FORKLIFT (FL)"
                },
                {
                    "code": "66",
                    "name": "FWRD CONT (FC)"
                },
                {
                    "code": "67",
                    "name": "GARBGE TK (GG)"
                },
                {
                    "code": "68",
                    "name": "GENERATOR (GE)"
                },
                {
                    "code": "69",
                    "name": "GLASS RAK (GR)"
                },
                {
                    "code": "70",
                    "name": "GLIDER (GL)"
                },
                {
                    "code": "71",
                    "name": "GO KART (GK)"
                },
                {
                    "code": "72",
                    "name": "GOLF CART (GC)"
                },
                {
                    "code": "73",
                    "name": "GONDOLA (GA)"
                },
                {
                    "code": "74",
                    "name": "GRADER (GD)"
                },
                {
                    "code": "75",
                    "name": "GRAIN/BOX (GN)"
                },
                {
                    "code": "76",
                    "name": "HARDTOP (HT)"
                },
                {
                    "code": "77",
                    "name": "HARDTP CV (RH)"
                },
                {
                    "code": "78",
                    "name": "HATCHBACK (HB)"
                },
                {
                    "code": "79",
                    "name": "HEARSE (HR)"
                },
                {
                    "code": "80",
                    "name": "HOPPER (HO)"
                },
                {
                    "code": "81",
                    "name": "HORSE TLR (HE)"
                },
                {
                    "code": "82",
                    "name": "INC CHASS (IC)"
                },
                {
                    "code": "83",
                    "name": "INC EXTVN (IE)"
                },
                {
                    "code": "84",
                    "name": "JEEPSTER (JP)"
                },
                {
                    "code": "85",
                    "name": "LIMOUSINE (LM)"
                },
                {
                    "code": "86",
                    "name": "LIVESTOCK (LS)"
                },
                {
                    "code": "87",
                    "name": "LIVESTOCK (LI)"
                },
                {
                    "code": "88",
                    "name": "LOADER (LD)"
                },
                {
                    "code": "89",
                    "name": "LOGGER (LG)"
                },
                {
                    "code": "90",
                    "name": "LOGSKIDDR (LK)"
                },
                {
                    "code": "91",
                    "name": "LOW SPEED (LV)"
                },
                {
                    "code": "92",
                    "name": "LOWBOY (LB)"
                },
                {
                    "code": "93",
                    "name": "LUNCH WGN (LW)"
                },
                {
                    "code": "94",
                    "name": "MAXI VAN (MV)"
                },
                {
                    "code": "95",
                    "name": "MINI BIKE (MK)"
                },
                {
                    "code": "96",
                    "name": "MINI BK (MN)"
                },
                {
                    "code": "97",
                    "name": "MINI CYCL (MY)"
                },
                {
                    "code": "98",
                    "name": "MINI MOTO (MM)"
                },
                {
                    "code": "99",
                    "name": "MINI ROAD (MR)"
                },
                {
                    "code": "100",
                    "name": "MISC (XX)"
                },
                {
                    "code": "101",
                    "name": "MOBILE HM (HS)"
                },
                {
                    "code": "102",
                    "name": "MOBLE OFF (MB)"
                },
                {
                    "code": "103",
                    "name": "MOPED (MP)"
                },
                {
                    "code": "104",
                    "name": "MOPED (MD)"
                },
                {
                    "code": "105",
                    "name": "MOTO CROS (MX)"
                },
                {
                    "code": "106",
                    "name": "MOTOR HOM (MH)"
                },
                {
                    "code": "107",
                    "name": "MOTORCYCL (MC)"
                },
                {
                    "code": "108",
                    "name": "MOTORCYCL (RS)"
                },
                {
                    "code": "109",
                    "name": "MTR SCOOT (MS)"
                },
                {
                    "code": "110",
                    "name": "MTRCL 3WL (MW)"
                },
                {
                    "code": "111",
                    "name": "MTRCY TRL (MT)"
                },
                {
                    "code": "112",
                    "name": "NOTCHBACK (NB)"
                },
                {
                    "code": "113",
                    "name": "OFF-ROAD (DT)"
                },
                {
                    "code": "114",
                    "name": "ONE SEAT (S1)"
                },
                {
                    "code": "115",
                    "name": "OPEN BODY (OP)"
                },
                {
                    "code": "116",
                    "name": "OPERA CP (OC)"
                },
                {
                    "code": "117",
                    "name": "PALLET (PL)"
                },
                {
                    "code": "118",
                    "name": "PANEL (PN)"
                },
                {
                    "code": "119",
                    "name": "PARCL DLV (PD)"
                },
                {
                    "code": "120",
                    "name": "PARK MODL (PA)"
                },
                {
                    "code": "121",
                    "name": "PASS CAR (PC)"
                },
                {
                    "code": "122",
                    "name": "PASS VAN (PV)"
                },
                {
                    "code": "123",
                    "name": "PHAETON (PH)"
                },
                {
                    "code": "124",
                    "name": "PICKUP (PK)"
                },
                {
                    "code": "125",
                    "name": "PICKUP RV (PM)"
                },
                {
                    "code": "126",
                    "name": "PK CAMPER (PR)"
                },
                {
                    "code": "127",
                    "name": "POLE/PIPE (LP)"
                },
                {
                    "code": "128",
                    "name": "PUMPER (PP)"
                },
                {
                    "code": "129",
                    "name": "RACER CYC (RC)"
                },
                {
                    "code": "130",
                    "name": "REEFER (RF)"
                },
                {
                    "code": "131",
                    "name": "ROAD/TRAL (RT)"
                },
                {
                    "code": "132",
                    "name": "ROADSTER (RD)"
                },
                {
                    "code": "133",
                    "name": "ROLLER (RO)"
                },
                {
                    "code": "134",
                    "name": "RUNABOUT (RU)"
                },
                {
                    "code": "135",
                    "name": "SCRAPER (SC)"
                },
                {
                    "code": "136",
                    "name": "SEDAN (SD)"
                },
                {
                    "code": "137",
                    "name": "SEDAN DEL (SL)"
                },
                {
                    "code": "138",
                    "name": "SEDAN DEL (NM)"
                },
                {
                    "code": "139",
                    "name": "SEMI TLR (SE)"
                },
                {
                    "code": "140",
                    "name": "SHOVEL (SH)"
                },
                {
                    "code": "141",
                    "name": "SIDE CAR (SR)"
                },
                {
                    "code": "142",
                    "name": "SKDSTRLDR (SK)"
                },
                {
                    "code": "143",
                    "name": "SNGL WHEL (1W)"
                },
                {
                    "code": "144",
                    "name": "SNOW RMVL (SO)"
                },
                {
                    "code": "145",
                    "name": "SNOWBL TL (SM)"
                },
                {
                    "code": "146",
                    "name": "SP.EQUIP. (SQ)"
                },
                {
                    "code": "147",
                    "name": "SP-CONSTD (SX)"
                },
                {
                    "code": "148",
                    "name": "SPEEDSTER (SZ)"
                },
                {
                    "code": "149",
                    "name": "SPORT (SP)"
                },
                {
                    "code": "150",
                    "name": "SPORT VAN (SV)"
                },
                {
                    "code": "151",
                    "name": "SPORT VAN (VA)"
                },
                {
                    "code": "152",
                    "name": "SPORTTRAC (E1)"
                },
                {
                    "code": "153",
                    "name": "ST SWEEPR (SS)"
                },
                {
                    "code": "154",
                    "name": "STAKE/RAK (ST)"
                },
                {
                    "code": "155",
                    "name": "STEP VAN (SN)"
                },
                {
                    "code": "156",
                    "name": "STN WAGON (SW)"
                },
                {
                    "code": "157",
                    "name": "SUPCAB PK (PS)"
                },
                {
                    "code": "158",
                    "name": "SUPERCAB (CS)"
                },
                {
                    "code": "159",
                    "name": "TANDEM (TA)"
                },
                {
                    "code": "160",
                    "name": "TANKER (TN)"
                },
                {
                    "code": "161",
                    "name": "TENT TLR (TE)"
                },
                {
                    "code": "162",
                    "name": "TILT CAB (TB)"
                },
                {
                    "code": "163",
                    "name": "TILT TAND (TM)"
                },
                {
                    "code": "164",
                    "name": "TOURNG (TO)"
                },
                {
                    "code": "165",
                    "name": "TOW DOLLY (DO)"
                },
                {
                    "code": "166",
                    "name": "TOW DOLLY (TD)"
                },
                {
                    "code": "167",
                    "name": "TOW TRUCK (TT)"
                },
                {
                    "code": "168",
                    "name": "TOW TRUCK (TW)"
                },
                {
                    "code": "169",
                    "name": "TOW TRUCK (WK)"
                },
                {
                    "code": "170",
                    "name": "TRACTR TK (TU)"
                },
                {
                    "code": "171",
                    "name": "TRAILER (TL)"
                },
                {
                    "code": "172",
                    "name": "TRAVELALL (XT)"
                },
                {
                    "code": "173",
                    "name": "TRAVELALL (WW)"
                },
                {
                    "code": "174",
                    "name": "TRAVL TLR (TV)"
                },
                {
                    "code": "175",
                    "name": "TRENCHER (TH)"
                },
                {
                    "code": "176",
                    "name": "TRIALS (TR)"
                },
                {
                    "code": "177",
                    "name": "TRIKE (TI)"
                },
                {
                    "code": "178",
                    "name": "TRKCAMPER (TP)"
                },
                {
                    "code": "179",
                    "name": "TRL TRUCK (DS)"
                },
                {
                    "code": "180",
                    "name": "TRLR JCKY (TJ)"
                },
                {
                    "code": "181",
                    "name": "TROLLEY (TY)"
                },
                {
                    "code": "182",
                    "name": "TRUCK (TK)"
                },
                {
                    "code": "183",
                    "name": "TRUCKSTER (TS)"
                },
                {
                    "code": "184",
                    "name": "TUDOR (UD)"
                },
                {
                    "code": "185",
                    "name": "TWO SEAT (S2)"
                },
                {
                    "code": "186",
                    "name": "TWO WHEEL (2W)"
                },
                {
                    "code": "187",
                    "name": "UNFNSH TK (IP)"
                },
                {
                    "code": "188",
                    "name": "UNKNOWN# (UN)"
                },
                {
                    "code": "189",
                    "name": "UTILITY (WG)"
                },
                {
                    "code": "190",
                    "name": "UTILITY (AW)"
                },
                {
                    "code": "191",
                    "name": "UTILITY (CU)"
                },
                {
                    "code": "192",
                    "name": "UTILITY (UT)"
                },
                {
                    "code": "193",
                    "name": "UTV (UV)"
                },
                {
                    "code": "194",
                    "name": "VAN (VN)"
                },
                {
                    "code": "195",
                    "name": "VAN CAMPR (VC)"
                },
                {
                    "code": "196",
                    "name": "VANETTE (VT)"
                },
                {
                    "code": "197",
                    "name": "WAGON (WA)"
                },
                {
                    "code": "198",
                    "name": "WELDER (WE)"
                },
                {
                    "code": "199",
                    "name": "WELL DRIL (WD)"
                },
                {
                    "code": "200",
                    "name": "WHL LOADR (WL)"
                },
                {
                    "code": "201",
                    "name": "WINCH (WI)"
                },
                {
                    "code": "202",
                    "name": "WINDOW VN (VW)"
                },
                {
                    "code": "203",
                    "name": "XUV (XV)"
                },
                {
                    "code": "204",
                    "name": "YARD TRAC (MO)"
                },
                {
                    "code": "205",
                    "name": "2 DOOR (2D)"
                },
                {
                    "code": "206",
                    "name": "2C (2C)"
                },
                {
                    "code": "207",
                    "name": "2D HACHBK (2H)"
                },
                {
                    "code": "208",
                    "name": "2D HARDTP (2P)"
                },
                {
                    "code": "209",
                    "name": "2D HARDTP (2T)"
                },
                {
                    "code": "210",
                    "name": "2D HARDTP (3D)"
                },
                {
                    "code": "211",
                    "name": "2D HARDTP (2F)"
                },
                {
                    "code": "212",
                    "name": "2D LIFTBK (2L)"
                },
                {
                    "code": "213",
                    "name": "2D SEDAN (2S)"
                },
                {
                    "code": "214",
                    "name": "3 DOOR (3P)"
                },
                {
                    "code": "215",
                    "name": "3D LIFTBK (3L)"
                },
                {
                    "code": "216",
                    "name": "3DR EXTCC (3C)"
                },
                {
                    "code": "217",
                    "name": "3DR EXTCP (3B)"
                },
                {
                    "code": "218",
                    "name": "4 DOOR (4D)"
                },
                {
                    "code": "219",
                    "name": "4D HARDTP (4T)"
                },
                {
                    "code": "220",
                    "name": "4D HARDTP (4P)"
                },
                {
                    "code": "221",
                    "name": "4D HTCHBK (4H)"
                },
                {
                    "code": "222",
                    "name": "4D LIFTBK (4L)"
                },
                {
                    "code": "223",
                    "name": "4D SEDAN (4S)"
                },
                {
                    "code": "224",
                    "name": "4DR (C4)"
                },
                {
                    "code": "225",
                    "name": "4DR EXTCC (4B)"
                },
                {
                    "code": "226",
                    "name": "4DR EXTCP (4C)"
                },
                {
                    "code": "227",
                    "name": "4WHEEL DR (4W)"
                },
                {
                    "code": "228",
                    "name": "5 DOOR (5D)"
                },
                {
                    "code": "229",
                    "name": "5D HTCHBK (5H)"
                },
                {
                    "code": "230",
                    "name": "8PAS SPVN (8V)"
                }
            ],
            "vehicleTypeDropdown": [
                {
                    "code": "0",
                    "name": "Passenger"
                },
                {
                    "code": "1",
                    "name": "Motorcycle"
                },
                {
                    "code": "2",
                    "name": "Truck"
                },
                {
                    "code": "3",
                    "name": "Trailer"
                },
                {
                    "code": "4",
                    "name": "Motorhome"
                },
                {
                    "code": "5",
                    "name": "ATV"
                },
                {
                    "code": "6",
                    "name": "Low Speed Vehicle"
                },
                {
                    "code": "7",
                    "name": "Snowmobile"
                },
                {
                    "code": "8",
                    "name": "Van"
                },
                {
                    "code": "9",
                    "name": "Vessel"
                }
            ],
            "vehicleClassificationDropdown": [
                {
                    "code": "0",
                    "name": "First Division"
                },
                {
                    "code": "1",
                    "name": "Second Division"
                },
                {
                    "code": "2",
                    "name": "Unknown"
                }
            ],
            "titlingStateDropdown": [
                {
                    "code": "0",
                    "name": "Alaska"
                },
                {
                    "code": "1",
                    "name": "Alabama"
                },
                {
                    "code": "2",
                    "name": "Arkansas"
                },
                {
                    "code": "3",
                    "name": "Arizona"
                },
                {
                    "code": "4",
                    "name": "California"
                },
                {
                    "code": "5",
                    "name": "Colorado"
                },
                {
                    "code": "6",
                    "name": "Connecticut"
                },
                {
                    "code": "7",
                    "name": "District of Columbia"
                },
                {
                    "code": "8",
                    "name": "Delaware"
                },
                {
                    "code": "9",
                    "name": "Florida"
                },
                {
                    "code": "10",
                    "name": "Georgia"
                },
                {
                    "code": "11",
                    "name": "Hawaii"
                },
                {
                    "code": "12",
                    "name": "Iowa"
                },
                {
                    "code": "13",
                    "name": "Idaho"
                },
                {
                    "code": "14",
                    "name": "Illinois"
                },
                {
                    "code": "15",
                    "name": "Indiana"
                },
                {
                    "code": "16",
                    "name": "Kansas"
                },
                {
                    "code": "17",
                    "name": "Kentucky"
                },
                {
                    "code": "18",
                    "name": "Louisiana"
                },
                {
                    "code": "19",
                    "name": "Massachusetts"
                },
                {
                    "code": "20",
                    "name": "Maryland"
                },
                {
                    "code": "21",
                    "name": "Maine"
                },
                {
                    "code": "22",
                    "name": "Michigan"
                },
                {
                    "code": "23",
                    "name": "Minnesota"
                },
                {
                    "code": "24",
                    "name": "Missouri"
                },
                {
                    "code": "25",
                    "name": "Mississippi"
                },
                {
                    "code": "26",
                    "name": "Montana"
                },
                {
                    "code": "27",
                    "name": "North Carolina"
                },
                {
                    "code": "28",
                    "name": "North Dakota"
                },
                {
                    "code": "29",
                    "name": "Nebraska"
                },
                {
                    "code": "30",
                    "name": "New Hampshire"
                },
                {
                    "code": "31",
                    "name": "New Jersey"
                },
                {
                    "code": "32",
                    "name": "New Mexico"
                },
                {
                    "code": "33",
                    "name": "Nevada"
                },
                {
                    "code": "34",
                    "name": "New York"
                },
                {
                    "code": "35",
                    "name": "Ohio"
                },
                {
                    "code": "36",
                    "name": "Oklahoma"
                },
                {
                    "code": "37",
                    "name": "Oregon"
                },
                {
                    "code": "38",
                    "name": "Pennsylvania"
                },
                {
                    "code": "39",
                    "name": "Rhode Island"
                },
                {
                    "code": "40",
                    "name": "South Carolina"
                },
                {
                    "code": "41",
                    "name": "South Dakota"
                },
                {
                    "code": "42",
                    "name": "Tennessee"
                },
                {
                    "code": "43",
                    "name": "Texas"
                },
                {
                    "code": "44",
                    "name": "Utah"
                },
                {
                    "code": "45",
                    "name": "Virginia"
                },
                {
                    "code": "46",
                    "name": "Vermont"
                },
                {
                    "code": "47",
                    "name": "Washington"
                },
                {
                    "code": "48",
                    "name": "Wisconsin"
                },
                {
                    "code": "49",
                    "name": "West Virginia"
                },
                {
                    "code": "50",
                    "name": "Wyoming"
                }
            ],
            "colorDropdown": [
                {
                    "code": "0",
                    "name": "Beige"
                },
                {
                    "code": "1",
                    "name": "Black"
                },
                {
                    "code": "2",
                    "name": "Blue"
                },
                {
                    "code": "3",
                    "name": "Blue, dark"
                },
                {
                    "code": "4",
                    "name": "Blue, light"
                },
                {
                    "code": "5",
                    "name": "Brown"
                },
                {
                    "code": "6",
                    "name": "Bronze"
                },
                {
                    "code": "7",
                    "name": "Chrome/Steel"
                },
                {
                    "code": "8",
                    "name": "Copper"
                },
                {
                    "code": "9",
                    "name": "Cream/Ivory"
                },
                {
                    "code": "10",
                    "name": "Gold"
                },
                {
                    "code": "11",
                    "name": "Gray"
                },
                {
                    "code": "12",
                    "name": "Green"
                },
                {
                    "code": "13",
                    "name": "Green, dark"
                },
                {
                    "code": "14",
                    "name": "Green, light"
                },
                {
                    "code": "15",
                    "name": "Ivory"
                },
                {
                    "code": "16",
                    "name": "Lavender"
                },
                {
                    "code": "17",
                    "name": "Maroon/Burgundy"
                },
                {
                    "code": "18",
                    "name": "Orange"
                },
                {
                    "code": "19",
                    "name": "Pink"
                },
                {
                    "code": "20",
                    "name": "Purple"
                },
                {
                    "code": "21",
                    "name": "Red"
                },
                {
                    "code": "22",
                    "name": "Silver/Aluminum"
                },
                {
                    "code": "23",
                    "name": "Tan"
                },
                {
                    "code": "24",
                    "name": "Turquoise"
                },
                {
                    "code": "25",
                    "name": "White"
                },
                {
                    "code": "26",
                    "name": "Yellow"
                }
            ],
            "makeVisibilityExpression": "true",
            "modelVisibilityExpression": "false",
            "yearVisibilityExpression": "true",
            "showExpirationYear": false,
            "titleCaption": "Title #",
            "showRegistrationNumber": false,
            "showLicenseNumber": false,
            "showStateRegistered": false,
            "tradeInValueVisibilityExpression": "true",
            "tradeInValueOnChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
            "tradeInValueRequiredExpression": "true",
            "makeTypeAheadSource": "MakeByContainsJson",
            "vinVisibilityExpression": "true",
            "vinRequiredExpression": "true",
            "makeRequiredExpression": "true",
            "yearRequiredExpression": "true",
            "vehicleTypeRequiredExpression": "true",
            "vehicleTypeVisibilityExpression": "true",
            "vehicleClassificationVisibilityExpression": "!purchaseDate || (purchaseDate.substr(0,4) < 2022)",
            "vehicleClassificationRequiredExpression": "true",
            "vehicleClassificationOnChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
            "vehicleClassificationTooltipDescription": "Second Division vehicles are defined as those designed to carry more than 10 persons, be used as living quarters and/or pull or carry property, freight or cargo regardless of actual vehicle use (i.e. pickups). SUVs are considered FIrst Division vehicles.",
            "stateRegisteredTitle": "State Registered"
        },
        "visibilityExpression": "((saleTypeId == '0') && (tradeInVehicle && tradeInVehicle.tradeInVehicleSelected)) && (secondTradeInVehicle && secondTradeInVehicle.tradeInVehicleSelected)",
        "onChangeExpression": "\n\t\t\t\t\tvar currentYear = visibleRecord.purchaseDate != null ? visibleRecord.purchaseDate.substr(0,4) : null;\n\t\t\t\t\tvar tradeInValueOne = (visibleRecord.tradeInVehicle && visibleRecord.tradeInVehicle.tradeInVehicleSelected ? (visibleRecord.tradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueOne = (tradeInValueOne > 10000 && visibleRecord.tradeInVehicle.vehicleClassificationId == '0' && currentYear < 2022 ? 10000 : tradeInValueOne);\n\t\t\t\t\tvar tradeInValueTwo = (visibleRecord.secondTradeInVehicle && visibleRecord.secondTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.secondTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueTwo = (tradeInValueTwo > 10000 && visibleRecord.secondTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueTwo);\n\n\t\t\t\t\tvar tradeInValueThree = (visibleRecord.thirdTradeInVehicle && visibleRecord.thirdTradeInVehicle.tradeInVehicleSelected ? (visibleRecord.thirdTradeInVehicle.tradeInValue || 0) : 0);\n\t\t\t\t\ttradeInValueThree = (tradeInValueThree > 10000 && visibleRecord.thirdTradeInVehicle.vehicleClassificationId =='0' && currentYear < 2022 ? 10000 : tradeInValueThree);\n\t\t\t\t\tif(visibleRecord.saleTypeId == '0')\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.sellingPrice || 0) + (visibleRecord.documentFees || 0) + (visibleRecord.addOns || 0) + (visibleRecord.dealerServiceFee || 0) + (visibleRecord.deliveryFee || 0) - tradeInValueOne - tradeInValueTwo - tradeInValueThree;\n\t\t\t\t\t}\n\t\t\t\t\telse\n\t\t\t\t\t{\n\t\t\t\t\t\tvar taxableSellingPrice = (visibleRecord.leasePaymentAmount || 0) * ((visibleRecord.term || 0)-1) +(visibleRecord.amountDueAtSigning || 0);\n\t\t\t\t\t} \n\t\t\t\t\trecord.taxableSellingPrice = taxableSellingPrice >= 0 ? taxableSellingPrice : 0;",
        "line": 0
    },
    {
        "name": "leaseBuyout.isLeaseBuyout",
        "title": "Is lease buyout?",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(saleTypeId == '0') && (soldAsId == '1')",
        "line": 0
    },
    {
        "name": "leaseBuyout.buyoutTypeId",
        "title": "Buyout Type",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)",
        "lookup": [
            {
                "code": "0",
                "name": "Leasing Company Direct"
            },
            {
                "code": "1",
                "name": "Third Party Dealership/Broker"
            }
        ],
        "line": 0
    },
    {
        "name": "leaseBuyout.leasingCompanyName",
        "title": "Leasing Company Name",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')",
        "line": 0
    },
    {
        "name": "leaseBuyout.fein",
        "title": "FEIN #",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')",
        "line": 0
    },
    {
        "name": "leaseBuyout.leasingCompanyAddress",
        "title": "Leasing Company Address",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "Address",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "addressParams": {
            "enableRemoteAddressVerification": false,
            "showAddressLine": true,
            "showAddressLine2": false,
            "disableStateCode": true,
            "disableCounty": true,
            "disableCity": true,
            "showCounty": true,
            "allowCrossStateZipCodesExpression": "true"
        },
        "visibilityExpression": "(((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')",
        "line": 0
    },
    {
        "name": "leaseBuyout.isTaxesRemittedBySeller",
        "title": "Taxes remitted by seller?",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "BooleanRadio",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')",
        "line": 0
    },
    {
        "name": "leaseBuyout.salesTaxIdNumber",
        "title": "Sales Tax ID #",
        "section": "Lease Buyout Info",
        "required": true,
        "type": "Text",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((((saleTypeId == '0') && (soldAsId == '1')) && (leaseBuyout && leaseBuyout.isLeaseBuyout)) && (leaseBuyout && leaseBuyout.buyoutTypeId == '0')) && (leaseBuyout && leaseBuyout.isTaxesRemittedBySeller)",
        "line": 0
    },
    {
        "name": "seller",
        "title": "Seller",
        "section": "Sale Info",
        "required": false,
        "type": "Seller",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "sellerParams": {
            "showName": true,
            "showAddress": true,
            "billToIdVisibilityExpression": "true",
            "billToIdTooltipExpression": "'This field is used under specialized transaction conditions for custom billing procedures'",
            "billToIdDisabledExpression": "!true",
            "phoneVisibilityExpression": "true",
            "phoneRequiredExpression": "false"
        },
        "line": 0
    },
    {
        "name": "registrationActionTypeId",
        "title": "Registration Action",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "applicationTypeId != '1'",
        "lookup": [
            {
                "code": "0",
                "name": "New Registration"
            },
            {
                "code": "1",
                "name": "Plate Transfer"
            }
        ],
        "line": 0
    },
    {
        "name": "registrationDate",
        "title": "Registration Date",
        "section": "Title and Registration Info",
        "required": true,
        "type": "Date",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "dateParams": {
            "minDate": "01/01/1900"
        },
        "visibilityExpression": " applicationTypeId != '1' && registrationActionTypeId == '0'",
        "line": 0
    },
    {
        "name": "plateCategoryId",
        "title": "Plate Category",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "((applicationTypeId != '1') && (registrationActionTypeId != '1')) && (vehicleTypeId != '5' && vehicleTypeId != '3' && vehicleTypeId != '9' && vehicleTypeId != '7' && vehicleTypeId != '6')",
        "lookup": [
            {
                "code": "4",
                "name": "Disabled Plates"
            },
            {
                "code": "1",
                "name": "Personalized Plates"
            },
            {
                "code": "3",
                "name": "Ride Share"
            },
            {
                "code": "2",
                "name": "Specialty Plates"
            },
            {
                "code": "0",
                "name": "Standard Plates"
            }
        ],
        "lookupVisibilityExpression": "($current == '0' || $current == '4')",
        "line": 0
    },
    {
        "name": "plateTypeId",
        "title": "Plate Type",
        "section": "Title and Registration Info",
        "required": true,
        "type": "DropDown",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(((applicationTypeId != '1') && (registrationActionTypeId != '1')) && (vehicleTypeId != '5' && vehicleTypeId != '3' && vehicleTypeId != '9' && vehicleTypeId != '7' && vehicleTypeId != '6')) && (plateCategoryId == '4')",
        "lookup": [
            {
                "code": "0",
                "name": "Disabled Veteran Plate"
            },
            {
                "code": "1",
                "name": "Standard Disabled Plate"
            },
            {
                "code": "2",
                "name": "Hearing Impaired Plate"
            }
        ],
        "line": 0
    },
    {
        "name": "registrationExpirationDate",
        "title": "Registration Expiration Date",
        "section": "Title and Registration Info",
        "required": false,
        "type": "Date",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "dateParams": {
            "minDate": "RegistrationExpirationDateMinValue",
            "maxDate": "RegistrationExpirationDateMaxValue"
        },
        "visibilityExpression": "(applicationTypeId != '1') && (registrationActionTypeId == '1')",
        "requiredExpression": "true",
        "tooltip": "Plates expiring in 90 days or less of processing may incur an additional renewal fee.",
        "line": 0
    },
    {
        "name": "disabledPlatePlacard",
        "title": "Applying for disabled parking placard?",
        "section": "Title and Registration Info",
        "required": true,
        "type": "CheckBox",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "visibilityExpression": "(applicationTypeId != '1') && (vehicleTypeId != '5' && vehicleTypeId != '3' && vehicleTypeId != '9' && vehicleTypeId != '7' && vehicleTypeId != '6')",
        "line": 0
    },
    {
        "name": "registrationAddress",
        "title": "Registered Address",
        "section": "Title and Registration Info",
        "required": true,
        "type": "Address",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "addressParams": {
            "enableRemoteAddressVerification": true,
            "showAddressLine": true,
            "showAddressLine2": true,
            "disableStateCode": true,
            "disableCounty": false,
            "disableCity": false,
            "showCounty": true
        },
        "visibilityExpression": "applicationTypeId != '1'",
        "line": 0
    },
    {
        "name": "residenceAddress",
        "title": "Residence Address",
        "section": "Owner Info",
        "required": true,
        "type": "Address",
        "minLength": 0,
        "minValue": 0,
        "formControlSize": 1,
        "addressParams": {
            "enableRemoteAddressVerification": true,
            "showAddressLine": true,
            "showAddressLine2": true,
            "disableStateCode": true,
            "disableCounty": true,
            "disableCity": true,
            "showCounty": true
        },
        "visibilityExpression": "applicationTypeId == '1'",
        "line": 0
    }
]
